import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  Box,
  Link,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormatNumber } from "../../../utils/formatNumber";
import { useState } from "react";
import ViewPriceList from "./ViewPriceList";

const PreIpoList = ({ list, token }) => {
  const navigate = useNavigate();
  const [isPriceSlabOpen, setIsPriceSlabOpen] = useState<boolean>(false);
  const [modelData, setModelData] = useState<any>(null);

  return (
    // <TableContainer component={Paper}>
    <>
      <Grid
        container
        xs={12}
        sx={{
          paddingTop: "20px",
          paddingLeft: "20px",
        }}
      >
        {list?.map((row: any) => {
          return (
            <Grid xs={12} sm={6} lg={4} xl={3}>
              <Card
                sx={{
                  height: "390px",
                  boxShadow: 3,
                  borderRadius: 2,
                  backgroundColor: "#f9fafb",
                  position: "relative",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: 6,
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ p: 1, height: "100px" }}
                >
                  <Grid item xs={2}>
                    <CardMedia
                      component="img"
                      image={row?.company.logo_url}
                      alt="Logo"
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: "10px",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1" fontWeight="bold">
                      {row.company.display_name}
                    </Typography>
                    {row.company?.sector && (
                      <Typography
                        variant="caption"
                        sx={{
                          backgroundColor: "#E3F2FD",
                          padding: "4px 8px",
                          borderRadius: 1,
                        }}
                      >
                        {row.company.sector}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <CardContent sx={{ px: 3 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 1,
                      mb: 2,
                      display: "-webkit-box", // Use flexbox behavior
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Add ellipsis for overflowing text
                      WebkitBoxOrient: "vertical", // Specify box orientation
                      WebkitLineClamp: 3,
                    }}
                  >
                    {row.company.description}
                  </Typography>
                  <Grid container spacing={-2} xs={12}>
                    <Grid xs={6}>
                      <Box sx={{}}>
                        <Typography variant="body2" fontWeight="bold">
                          Price per share
                        </Typography>
                        <Typography variant="body2">
                          {row.price.price ?? "N/A"}
                        </Typography>
                      </Box>
                    </Grid>

                    {row.distribution?.sales ? (
                      <Grid xs={6}>
                        <Box sx={{}}>
                          <Typography variant="body2" fontWeight="bold">
                            Sales
                          </Typography>
                          <Typography variant="body2">
                            {FormatNumber(row.distribution.sales)}
                          </Typography>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid xs={6}>
                        <Box sx={{}}>
                          <Typography variant="body2" fontWeight="bold">
                            Market Cap
                          </Typography>
                          <Typography variant="body2">
                            {FormatNumber(row.distribution.market_cap)}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid xs={6} sx={{ marginTop: 3 }}>
                      <Box sx={{}}>
                        <Typography variant="body2" fontWeight="bold">
                          Minimum Units
                        </Typography>
                        <Typography variant="body2">
                          {FormatNumber(row.min_units)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={6} sx={{ marginTop: 3 }}>
                      <Box sx={{}}>
                        <Typography variant="body2" fontWeight="bold">
                          Maximum Units
                        </Typography>
                        <Typography variant="body2">
                          {FormatNumber(row.max_units)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* <Link
                    href="#"
                    variant="body2"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginTop: 3,
                    }}
                  >
                    Read: How {row.company.display_name} profitable!
                  </Link> */}
                </CardContent>
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    gap: "20px",
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "10px",
                      width: "100%",
                      borderRadius: "5px",
                      textAlign: "center",
                      border: "1px solid #ffa500",
                      color: "#ffa500",
                      background: "#ffffff",
                      cursor: "pointer",
                      fontSize: "bold",
                      transition: "transform 0.3s ease, box-shadow 0.5s ease",
                      "&:hover": {
                        background: "#ffa500",
                        color: "white",
                      },
                    }}
                    onClick={
                      (e) => {
                        setModelData({
                          date: row.price.trade_date,
                          isin: row.isin,
                          price: row?.price?.price,
                        });

                        setIsPriceSlabOpen(true);
                      }
                      // navigate(`/dashboardAdmin/apply-ipo`, {
                      //   state: { value: row },
                      // })
                    }
                  >
                    Grab Discount
                  </Typography>
                  <Typography
                    sx={{
                      padding: "10px",
                      width: "100%",
                      borderRadius: "5px",
                      textAlign: "center",
                      color: "#1976D2",
                      border: "1px solid #1976D2",
                      cursor: "pointer",
                      transition: "transform 0.3s ease, box-shadow 0.5s ease",
                      "&:hover": {
                        background: "#1976D2",
                        color: "white",
                      },
                    }}
                    onClick={(e) =>
                      navigate(`/dashboardAdmin/pre-ipo-explore/${row.isin}`, {
                        state: { value: row },
                      })
                    }
                  >
                    View Details
                  </Typography>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {isPriceSlabOpen && (
        <ViewPriceList
          isPriceSlabOpen={isPriceSlabOpen}
          setIsPriceSlabOpen={setIsPriceSlabOpen}
          price={modelData.price}
          token={token}
          date={modelData.date}
          isin={modelData.isin}
        />
      )}
    </>

    // </TableContainer>
  );
};

export default PreIpoList;
