import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import "./../../../styles/invoice.css"; // External CSS for styling

import "rsuite/dist/rsuite.min.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { FormatNumber } from "../../../utils/formatNumber";
import Loading from "../../Dashboard/Loading";
import Datefilter from "../../../utils/Datefilter";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function OtherInvestmentDateWiseTransactions({
  accessToken,
}: any) {
  const [tranx, setTranx] = useState<any>([]);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const { id } = useParams();

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      renderHeader: () => <strong>{"S.No"}</strong>,
    },
    {
      field: "assetType",
      headerName: "Asset",
      width: 180,
      renderHeader: () => <strong>{"Asset"}</strong>,
      renderCell: (params) => {
        let { assetType } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {assetType}
          </div>
        );
      },
    },
    {
      field: "issuer",
      headerName: "Issuer",
      width: 250,
      renderHeader: () => <strong>{"Issuer"}</strong>,
      renderCell: (params) => {
        let { issuer } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {issuer}
          </div>
        );
      },
    },
    {
      field: "instrument",
      headerName: "Instrument",
      width: 450,
      renderHeader: () => <strong>{"Instrument"}</strong>,
      renderCell: (params) => {
        let { instrument } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {instrument}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 140,
      renderHeader: () => <strong>{"Date"}</strong>,
      renderCell: (params) => {
        let { date } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "start", // Center text inside the div
            }}
          >
            {date ? moment(date).format("DD/MM/YYYY") : ""}
          </div>
        );
      },
    },
    {
      field: "transactionType",
      headerName: "Transaction",
      width: 180,
      renderHeader: () => <strong>{"Transaction"}</strong>,
      renderCell: (params) => {
        const { investedAmount } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {investedAmount ? "Purchase" : "Redeem"}
          </div>
        );
      },
    },
    {
      field: "investedAmount",
      headerName: "Amount",
      width: 180,
      renderHeader: () => <strong>{"Amount"}</strong>,
      renderCell: (params) => {
        const { totalAmount, redeemAmount } = params.row;

        let amount = "N/A";

        if (totalAmount) {
          amount = FormatNumber(totalAmount);
        }

        if (redeemAmount) {
          amount = FormatNumber(redeemAmount);
        }

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">Rs. {amount}</Typography>
          </Box>
        );
      },
    },
  ]);
  const [loading, setLoading] = useState(false);

  const dateConverter = (str) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(str);
    var mnth = date.getMonth();
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  };

  const [IntervalDate, setIntervalDate] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const [Interval, setInterval] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const getTranxData = async () => {
    const startDate = new Date(IntervalDate.startDate).getTime();
    const endDate = new Date(IntervalDate.endDate).getTime();
    const diff = endDate - startDate;
    const ThirtyDays = 1000 * 60 * 60 * 24 * 30;
    if (diff > ThirtyDays) {
      setLoading(false);
      setMessage("Difference between the dates cannot be more than 30 days");
      setError(true);
      return;
    }

    // Adjust the end date to the end of the selected day
    const endOfDay = new Date(IntervalDate.endDate);
    endOfDay.setHours(23, 59, 59, 999);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/other/dateWiseTransactions`,
        {
          from_date: new Date(IntervalDate.startDate),
          to_date: endOfDay,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const { data } = res;
        setLoading(false);

        if (!data.succ) {
          setError(true);
          setMessage(data.message);
          setTranx([]);
          return;
        }

        setTranx(data.data);
      })
      .catch((error) => {
        console.log("error from get pre ipo transaction api ->", error);

        setLoading(false);
        setError(true);
        setTranx([]);
        setMessage("Failed to Fetching Data from Server");
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    getTranxData();
  }, []);

  const changeHandler = (name, value) => {
    setIntervalDate((prev) => ({
      ...prev,
      [name]: dateConverter(value),
    }));
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={2} xs>
          <Grid item xs={12} sx={{ ml: { xs: 0, md: 4 }, height: "100vh" }}>
            <Snackbar
              open={isError}
              autoHideDuration={6000}
              onClose={() => setError(false)}
            >
              <Alert severity="error">{message}</Alert>
            </Snackbar>
            <Snackbar
              open={isSuccess}
              autoHideDuration={6000}
              onClose={() => setSuccess(false)}
            >
              <Alert severity="success">{message}</Alert>
            </Snackbar>
            <AppBar
              sx={{
                backgroundColor: "white",
                marginLeft: { xs: "5%", md: 0 },
                display: "flex",
                width: { xs: "96vw", md: "81.5vw" },
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              position="static"
              elevation={0}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  padding: 0,
                }}
              >
                <Datefilter
                  name="startDate"
                  label="From Date"
                  value={IntervalDate.startDate}
                  changeHandler={changeHandler}
                  maxDate={Interval.endDate}
                  minDate={Interval.startDate}
                  inputFormat="dd-MMM-yyyy"
                />
                <Datefilter
                  name="endDate"
                  label="To Date"
                  value={IntervalDate.endDate}
                  changeHandler={changeHandler}
                  maxDate={Interval.endDate}
                  minDate={Interval.startDate}
                  inputFormat="dd-MMM-yyyy"
                />
                <Button
                  sx={{ marginTop: "-3px", height: "37px" }}
                  variant="contained"
                  color="primary"
                  onClick={getTranxData}
                >
                  Search
                </Button>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                height: "85vh",
                display: { xs: "none", sm: "block" },
                width: { xs: "96.5vw", md: "80vw" },
                ml: { xs: 3, sm: 0 },
              }}
            >
              {tranx?.length > 0 && (
                <DataGrid
                  sx={{ mt: 1 }}
                  getRowHeight={() => "auto"}
                  //  hideFooter={true}
                  rowsPerPageOptions={[50, 100, 1000]}
                  // rows={rows}
                  rows={tranx?.map((each: any, idx: number) => {
                    return { ...each, id: idx + 1 };
                  })}
                  columns={columns.map((each, idx) => {
                    return {
                      id: idx + 1,
                      ...each,
                      headerAlign: "center",
                      align: "center",
                      sx: {
                        size: 2,
                      },
                    };
                  })}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
