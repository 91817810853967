import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Snackbar,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const SuperAdminGoldAccountModal = ({
  openAccount,
  setOpenAccount,
  accessToken,
  userData,
  userAddress,
  setIsUpdated,
  setSuccess,
  setError,
  setMsg,
}: any) => {
  const { id } = useParams();

  const [name, setName] = useState<string>(userData ? userData.name : "");
  const [mobile, setMobile] = useState<string>(
    userData ? userData.mobileNumber : ""
  );
  const [email, setEmail] = useState<string>(userData ? userData.email : "");
  const [address, setAddress] = useState<string>(
    userAddress ? userAddress.address : ""
  );
  const [pincode, setPincode] = useState<string>(
    userAddress ? userAddress.pincode : ""
  );
  const [cities, setCities] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<any>(
    userAddress?.city
      ? { value: userAddress.city.id, label: userAddress.city.name }
      : null
  );
  const [selectedState, setSelectedState] = useState<any>(
    userAddress?.state
      ? { value: userAddress.state.id, label: userAddress.state.name }
      : null
  );
  const [dob, setDob] = useState(
    userData && userData?.dob
      ? new Date(userData.dob).toISOString().split("T")[0]
      : ""
  );
  console.log("userData ->", userData);

  const [isFailure, setIsFailure] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [errorMsg, setErrorMsg] = useState("");
  const [nameError, setNameError] = useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenAccount(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    if (/\d/.test(name)) {
      setNameError(true);
      setErrorMsg("Name Must Contain Only letters and Spaces.");
      return;
    }

    let accountObj = {
      mobileNumber: mobile,
      name: name,
      email: email,
      address: address,
      city: {
        id: cities[0].id,
        name: cities[0].name,
      },
      state: {
        id: states[0].id,
        name: states[0].name,
      },
      pincode: pincode,
      dob: dob,
    };

    setIsLoading(true);

    if (userData?.unique_id) {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_HOST}v1/super/edit/gold/profile`,
          { ...accountObj, user_id: id },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(({ data }) => {
          if (!data.succ) {
            setError(true);
            setMsg(data.message);
            console.log("Error from edit gold account ->", data.message);
          }

          setSuccess(true);
          handleClose();
          setIsUpdated(true);
          setMsg(data.message);
        })
        .catch((error) => {
          console.log(" Error from edit gold account  ->", error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/super/create/gold/account`,
          { user_id: id, ...accountObj },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(({ data }) => {
          if (!data.succ) {
            console.log("Error from create from gold account ->", data.message);
            setError(true);
            setMsg(data.message);
          }

          setSuccess(true);
          handleClose();
          setIsUpdated(true);
          setMsg(data.message);
        })
        .catch((error) => {
          console.log(" Error from create from gold account  ->", error);
        });
    }
    setIsLoading(false);
  };

  const handleCityChange = (event: any, city: any) => {
    if (city !== "") {
      fetchCities(city);
    }
  };

  const fetchCities = async (city) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/cities?name=${city}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const dataObj = response.data;

        if (!dataObj.succ) {
          setError(true);
          setMsg(dataObj.message);
          return;
        }

        let data = dataObj.data;
        data.map((city: any) => {
          return {
            value: city.id,
            label: city.name,
          };
        });
        setCities(data);
      })
      .catch((error) => {
        console.log("Fetch Cities Error ->", error);
      });
  };

  const handleStateChange = (event: any, city: any) => {
    if (city !== "") {
      fetchStates(city);
    }
  };

  const fetchStates = async (state) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/states?name=${state}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => {
        const dataObj = response.data;

        if (!dataObj.succ) {
          setIsFailure(true);
          setMsg(dataObj.message);
          return;
        }

        let data = dataObj.data;
        data.map((state: any) => {
          return {
            value: state.id,
            label: state.name,
          };
        });
        setStates(data);
      })
      .catch((error) => {
        console.log("Fetch Cities Error ->", error);
      });
  };

  const handleCloseSnackbar = () => {
    setIsFailure(false);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openAccount}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Account Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              error={nameError}
              helperText={
                errorMsg || "Name Must Contain Only letters and Spaces."
              }
              onChange={(e) => setName(e.target.value)}
              required
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              fullWidth
              margin="normal"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />

            <TextField
              type="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />

            <Autocomplete
              fullWidth
              freeSolo
              options={cities.map((s: any) => s.name)}
              value={selectedCity}
              onChange={(e, value) => setSelectedCity(value)}
              onInputChange={(event, newInputValue) =>
                handleCityChange(event, newInputValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  name="selectedCity"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              )}
            />
            <Autocomplete
              fullWidth
              freeSolo
              options={states.map((s: any) => s.name)}
              value={selectedState}
              // onChange={(e, value) => handleState(value)}
              onInputChange={(event, newInputValue) =>
                handleStateChange(event, newInputValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  name="Scheme"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              )}
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Pincode"
              variant="outlined"
              fullWidth
              margin="normal"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              required
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                onChange={(date: any) => setDob(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={false} // Ensure error prop is explicitly set to false
                    helperText="" // Remove any helper text
                    margin="normal"
                    required
                    fullWidth
                  />
                )}
                value={dob}
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {/* <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success">{`Submitted. ${msg}`}</Alert>
      </Snackbar>
      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar> */}
    </Fragment>
  );
};
export default SuperAdminGoldAccountModal;
