import {
  Typography,
  Card,
  CardContent,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  Checkbox,
  Button,
  List,
  ListItem,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Divider,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  Name_Days,
  isholiday,
  ProductCode,
  metalType,
  purchaseType,
  goldRedeemOption,
} from "../../../Data/data";
import {
  dateConverter,
  FormatNumber,
  FormatNumberRedeem,
  FormatNumberRedeemForGold,
  parseNumber,
} from "../../../utils/formatNumber";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../Store/SocketContext";
import axios from "axios";
import { id } from "date-fns/locale";
import DeliverProducts from "./DeliverProducts";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { load } from "@cashfreepayments/cashfree-js";

export default function GoldRedeem({ accessToken }) {
  const { state }: any = useLocation();
  const { key, value } = state;
  const { socketData } = useContext(SocketContext);

  const [blockId, setBlockId] = useState<string>("");
  const [goldSellPrice, setGoldSellPrice] = useState<number>(0);
  const [silverSellPrice, setSilverSellPrice] = useState<number>(0);
  const [banks, setBanks] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [countError, setCountError] = useState<string>("");
  const [addresses, setAddresses] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [submitClose, setSubmitClose] = useState<boolean>(false);
  const [cashFree, setCashFree] = useState<any>(null);
  const [redeemableUnits, setRedeemableUnits] = useState<number>(0);
  const [totalGrams, setTotalGrams] = useState<number>(0);
  const [timeRemaining, setTimeRemaining] = useState(4 * 60); // Convert minutes to seconds
  const [price, setPrice] = useState<string>("0");
  const [totalQuantity, setTotalQuantity] = useState<number>(
    value.totalQuantity
  );
  const [isAllUnits, setAllUnits] = useState(false);
  const [redeemType, setRedeemType] = useState<string>("");
  const [selectedPurchaseType, setSelectedPurchaseType] = useState<any>();
  const [receiver, setReceiver] = useState<{
    uniqueId: string;
    name: string;
    mobileNumber: string;
  }>({ uniqueId: "", name: "", mobileNumber: "" });
  const [qty, setQty] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [bankId, setBankId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [Msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [metalErrorMsg, setMetalErrorMsg] = useState("");
  const [metalError, setMetalError] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const now = new Date();
    const currentMinutes = now.getMinutes();

    const nextTargetMinute = Math.ceil((currentMinutes + 1) / 4) * 4;
    const targetTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      nextTargetMinute,
      0
    );

    const duration = Math.floor((targetTime.getTime() - now.getTime()) / 1000); // Convert ms to seconds

    setTimeRemaining(duration); // Set initial time

    if (duration < 1) {
      const initialPrice =
        key === "gold" ? socketData?.gSell : socketData?.sSell;

      if (initialPrice) {
        localStorage.setItem("metalPrice", initialPrice);
        localStorage.setItem("block_id", socketData?.block_id);

        setPrice(initialPrice);
      }
    }
  }, [socketData]);

  useEffect(() => {
    const initialPrice = key === "gold" ? socketData?.gSell : socketData?.sSell;
    if (initialPrice) {
      localStorage.setItem("metalPrice", initialPrice);
      localStorage.setItem("block_id", socketData?.block_id);
      setPrice(initialPrice);
    }
  }, []);

  useEffect(() => {
    const fetchCashFree = async () => {
      try {
        const data = await load({
          mode: process.env.REACT_APP_CASHFREE_ENVIRONMENT,
        });
        setCashFree(data); // Store the loaded data in state
        // Handle the cashFree data here
      } catch (error) {
        // Handle any errors here
        console.error("Error loading CashFree:", error);
      }
    };

    fetchCashFree();
  }, []);

  // Fetch User Banks and Redeemable Units

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank/all`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const res = response.data.data;

        const banks = res.map((bank: any) => {
          return {
            label: `${bank.bankName} (${bank.accountNumber})`,
            value: bank.bank_id,
          };
        });

        setBanks(banks);
      })
      .catch((error) => {
        console.log("error from get user banks ->", error);
      });
    getRedeemableUnits();
  }, []);

  const getRedeemableUnits = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/redeemableUnits?metal=${key}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        setRedeemableUnits(data.redeemableUnits);
      })
      .catch((error) => {
        console.log("error from get user banks ->", error);
      });
  };

  useEffect(() => {
    const total = selectedProducts.reduce((sum, product) => {
      const weight = parseFloat(product.totalQuantity ?? 1) || 0;
      return sum + weight;
    }, 0);

    const redeemableTotal = selectedProducts.reduce((sum, product) => {
      const weight =
        parseFloat(product.totalGrams ?? product.redeemWeight) || 0;
      return sum + weight;
    }, 0);

    setTotalGrams(redeemableTotal);
    if (redeemableTotal <= totalQuantity) {
      setTotalCount(total);
      setSubmitClose(false);
      setCountError("");
    } else {
      setSubmitClose(true);
      setCountError("You can not selected more then total quantity");
    }

    console.log("selectedProducts ->", selectedProducts);
  }, [selectedProducts]);

  const handleChangePurchaseType = async (e: any) => {
    let { name, value } = e.target;

    setErrorMsg("");
    setError(false);
    setMetalError(false);
    setMetalErrorMsg("");
    setQty(0);
    setTotalAmount(0);
    setSubmitClose(false);

    if (name === "redeem_options") {
      setRedeemType(value);
      setSelectedPurchaseType("");
      setIsLoading(false);
      setQty(0);
      setTotalAmount(0);

      if (value === "Delivery") {
        // Get Products Data
        let response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/products?page=1&metal=${key}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        // Get Address Data
        let AddResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/address`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        // Set Address
        setAddresses(AddResponse.data.data);

        let productsData = response.data.data.products;
        setProducts(productsData);
        setIsProduct(true);
        setSelectedPurchaseType("quantity");
      } else {
        setIsProduct(false);
      }
    } else if (name === "sell_type") {
      setSelectedPurchaseType(value);
      setQty(0);
    }
  };

  const handleChangeAddress = (e: any) => {
    let { value } = e.target;
    let selected = addresses.find((address) => address.address_id === value);
    setSelectedAddress(selected);
  };

  const handleQuantity = (product, basePrice, type) => {
    const updatedProducts = selectedProducts.map((p) =>
      p.sku === product.sku
        ? {
            ...p,
            totalQuantity:
              type === "increment"
                ? (p.totalQuantity || 1) + 1
                : Math.max((p.totalQuantity || 1) - 1, 1), // Prevent going below 1
            totalGrams:
              type === "increment"
                ? (p.totalGrams || parseFloat(p.redeemWeight)) +
                  parseFloat(p.redeemWeight)
                : Math.max(
                    (p.totalGrams || parseFloat(p.redeemWeight)) -
                      parseFloat(p.redeemWeight)
                  ), // Prevent going below 1
            totalCharge:
              type === "increment"
                ? (p.totalCharge || parseInt(p.basePrice, 10)) +
                  parseInt(basePrice, 10)
                : Math.max(
                    (p.totalCharge || parseInt(p.basePrice, 10)) -
                      parseInt(basePrice, 10),
                    parseInt(basePrice, 10)
                  ), // Prevent going below basePrice
          }
        : p
    );

    const total = updatedProducts.reduce((sum, product) => {
      return sum + (product.totalQuantity ?? 1);
    }, 0);

    const redeemableTotal = selectedProducts.reduce((sum, product) => {
      const weight =
        parseFloat(product.totalGrams ?? product.redeemWeight) || 0;
      return sum + weight;
    }, 0);

    if (redeemableTotal > totalQuantity) {
      setSubmitClose(true);
      setCountError("You cannot select more than the total quantity.");
    } else {
      setSubmitClose(false);
      setCountError("");
    }

    setTotalCount(total);
    setTotalGrams(redeemableTotal);

    console.log("updatedProducts ->", updatedProducts);

    setSelectedProducts(updatedProducts);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (selectedPurchaseType === "amount" && totalAmount < 10) {
      setMetalError(true);
      setMetalErrorMsg(`Redeem amount must be grater than ₹ 10.`);
      setSubmitClose(true);
      return;
    }

    if (
      redeemType === "Sell" &&
      selectedPurchaseType === "quantity" &&
      qty < 0.0015
    ) {
      setMetalError(true);
      setMetalErrorMsg(`Redeemable units must be grater than 0.0015 Grams.`);
      setSubmitClose(true);
      return;
    }

    if (!error) {
      setIsLoading(true);

      if (redeemType === "Sell" && (qty > 0 || totalAmount > 0)) {
        const data = {
          blockId: localStorage.getItem("block_id"),
          metalType: key,
          lockPrice: price,
          purchaseType: selectedPurchaseType,
          quantity: selectedPurchaseType === "quantity" ? qty : null,
          amount: selectedPurchaseType === "amount" ? totalAmount : null,
          bankId: bankId,
          totalAmount: totalAmount,
        };

        axios
          .post(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/sell`, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            const data = response.data;

            if (!data.succ) {
              setIsFailure(true);
              setIsLoading(false);
              setMsg(data.message);
              return;
            }

            // setDataEmpty(data);

            localStorage.setItem("order_id", data.data.order_id);

            navigate(`/dashboardAdmin/gold-transactions`);
          })
          .catch((error) => {
            setIsLoading(false);
            setIsFailure(true);
            // setMsg(data.message);
            return;
          });
      } else if (redeemType === "Sell") {
        setErrorMsg(`Please Enter Valid ${selectedPurchaseType}.`);
        setError(true);
        setIsLoading(false);
      }

      // if (
      //   redeemType === "Gift" &&
      //   (qty > 0 || totalAmount > 0 || receiver.uniqueId !== "")
      // ) {
      //   const data = {
      //     metalType: key,
      //     lockPrice: key === "silver" ? silverSellPrice : goldSellPrice,
      //     purchaseType: selectedPurchaseType,
      //     quantity: selectedPurchaseType === "quantity" ? qty : null,
      //     amount: selectedPurchaseType === "amount" ? totalAmount : null,
      //     receiver: receiver,
      //     totalAmount: totalAmount,
      //   };

      //   axios
      //     .post(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/gift`, data, {
      //       headers: { Authorization: `Bearer ${accessToken}` },
      //     })
      //     .then((response) => {
      //       const data = response.data;

      //       if (!data.succ) {
      //         setIsFailure(true);
      //         setIsLoading(false);
      //         setMsg(data.message);
      //         return;
      //       }

      //       setDataEmpty(data);
      //     })
      //     .catch((error) => {
      //       setIsLoading(false);
      //       setIsFailure(true);
      //       // setMsg(data.message);
      //       return;
      //     });
      // } else if (redeemType === "Gift") {
      //   setErrorMsg(
      //     receiver.uniqueId === ""
      //       ? "Please Enter Valid User Id."
      //       : `Please Enter Valid ${selectedPurchaseType}.`
      //   );
      //   setError(true);
      //   setIsLoading(false);
      // }

      if (redeemType === "Delivery") {
        if (totalCount === 0) {
          setIsFailure(true);
          setMsg("Please Select At Least One Product");
          return;
        }

        let products = selectedProducts.map((product) => {
          return {
            name: product.name,
            sku: product.sku,
            charges: product.basePrice,
            totalGrams: product.totalGrams,
            totalCharges: product.totalCharge ?? product.basePrice,
            totalQuantity: product.totalQuantity ?? product.redeemWeight,
          };
        });

        let totalShipCharges = products.reduce((sum, product) => {
          const weight =
            parseFloat(product.totalCharges ?? product.basePrice) || 0;

          return sum + weight;
        }, 0);

        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_HOST}v1/gold/deliver`,
            {
              address: selectedAddress,
              shippingCharges: totalShipCharges,
              totalQuantity: totalGrams,
              products: products,
              metalType: key,
              lockPrice: price,
              blockId: localStorage.getItem("block_id"),
            },
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
          .then(({ data }) => {
            if (!data.succ) {
              setIsFailure(true);
              setIsLoading(false);
              setMsg(data.message);
              return;
            }

            const { payment_session_id, order_id } = data.data; // Get payment_session_id from response

            localStorage.setItem("order_id", order_id);

            if (payment_session_id) {
              const options = {
                paymentSessionId: payment_session_id,
              };

              cashFree.checkout(options);
            }

            setDataEmpty(data);
          })
          .catch(({ response }) => {
            console.log("error from create account ->", response);
          });
      }
    }
  };

  const setDataEmpty = (data: any) => {
    setIsLoading(false);
    setIsSuccess(true);
    setIsLoading(false);
    setMsg(data.message);
    setTotalQuantity(
      key === "silver"
        ? parseFloat(data.sell.silverBalance)
        : parseFloat(data.sell.goldBalance)
    );
    setQty(0);
    setTotalAmount(0);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setMetalError(false);
    setSubmitClose(false);

    if (name === "redeem_units") {
      setAllUnits(false);

      let units = value === "" ? 0 : parseNumber(value);

      if (units > totalQuantity) {
        setMetalErrorMsg(
          `You Cannot Enter More than ${totalQuantity.toFixed(4)}.`
        );
        setMetalError(true);
        setSubmitClose(true);
        return;
      }

      if (key === "silver" && units > 20000) {
        setMetalErrorMsg("You Cannot Enter More than 20000.");
        setMetalError(true);
        setSubmitClose(true);
      } else if (key === "gold " && units > 1000 && redeemType === "Sell") {
        setMetalErrorMsg("You Cannot Enter More than 1000.");
        setMetalError(true);
        setSubmitClose(true);
      } else if (key === "gold " && redeemType === "Gift" && units > 250) {
        setMetalErrorMsg("You Cannot Enter More than 250.");
        setMetalError(true);
        setSubmitClose(true);
      } else {
        setMetalError(false);
      }

      const approxAmount =
        units * (key === "silver" ? silverSellPrice : goldSellPrice);

      setTotalAmount(approxAmount);
      setQty(value);
    }

    if (name === "redeem_amount") {
      const redeemAmount = totalQuantity * Number(price);

      setTotalAmount(value);

      if (value > redeemAmount) {
        setMetalError(true);
        setMetalErrorMsg(
          `Redeem amount cannot exceed  to Approx Value ${FormatNumberRedeem(
            redeemAmount
          )}.`
        );
        setSubmitClose(true);

        return;
      }

      if (value > 1000000) {
        setMetalError(true);
        setMetalErrorMsg(`Redeem amount cannot exceed Rs. 10,00,000.`);
        setSubmitClose(true);
      }

      setTotalAmount(value);
    }

    if (name === "bank") {
      setBankId(value);
    }
  };

  const handleCheck = (event: any) => {
    const { checked } = event.target;

    if (checked) {
      setAllUnits(true);

      setQty(totalQuantity);

      const approxAmount =
        totalQuantity * (key === "silver" ? silverSellPrice : goldSellPrice);

      setTotalAmount(approxAmount);
    } else {
      setAllUnits(false);
      setQty(0);
      setTotalAmount(0);
    }
  };

  const handleCloseSnackbar = () => {
    setIsFailure(false);
  };

  const handleMemberChange = (e: any) => {
    const { value } = e.target;

    if (value.length > 6) {
      return;
    }

    setReceiver({ uniqueId: value, name: "", mobileNumber: "" });
    if (value.length === 6) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/search/member?uniqueId=${value}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => {
          const data = response.data;

          if (!data.succ) {
            setIsLoading(false);
            setError(true);
            setErrorMsg(data.message);
            return;
          }

          setIsLoading(false);

          setReceiver({
            uniqueId: data.data.unique_id,
            name: data.data.name,
            mobileNumber: data.data.mobileNumber,
          });
          setError(false);

          setErrorMsg(`${data.data.name} - ${data.data.mobileNumber}`);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsFailure(true);
          // setMsg(data.message);
          return;
        });
    }
  };

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <>
      <Card sx={{ maxWidth: 600, margin: "0 auto", marginTop: "100px" }}>
        <CardContent>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              Redeem Request
            </Typography>
            <TextField
              label="Metal"
              name="Metal"
              value={key}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              select
              disabled
            >
              {metalType
                .filter((ele) => ele.value == key)
                .map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              type="text"
              label="Price"
              name="Price"
              variant="outlined"
              fullWidth
              margin="normal"
              value={key !== "" ? parseFloat(price).toFixed(2) : 0}
              disabled={key !== "" ? true : false}
              helperText={
                key !== "" ? (
                  <h5>Price Is Valid until {formatTime(timeRemaining)}</h5>
                ) : (
                  ""
                )
              }
            />

            <Box sx={{ display: "flex", flexDirection: "rows", gap: "10px" }}>
              <TextField
                label="Total Grams"
                name="total_investment"
                value={totalQuantity.toFixed(4)}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
              <TextField
                label="Approx. Value"
                name="total_investment"
                value={FormatNumber(Number(totalQuantity) * Number(price))}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
            </Box>
            <TextField
              label="Redeem Options"
              name="redeem_options"
              onChange={handleChangePurchaseType}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              select
            >
              {goldRedeemOption.map((ele: any, indx: any) => {
                return (
                  <MenuItem key={indx} value={ele.value}>
                    {ele.label}
                  </MenuItem>
                );
              })}
            </TextField>
            {redeemType === "Sell" && (
              <TextField
                label="Bank"
                name="bank"
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {banks?.map((ele: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={ele.value}>
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}

            {/* {redeemType === "Gift" && (
              <TextField
                label="Receiver UniqueId"
                value={receiver.uniqueId}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={handleMemberChange}
                error={error}
                helperText={errorMsg}
              />
            )} */}

            {redeemType !== "Delivery" ? (
              <TextField
                label="Sell Type"
                name="sell_type"
                onChange={handleChangePurchaseType}
                variant="outlined"
                margin="normal"
                // value={selectedPurchaseType}
                defaultValue={isProduct ? selectedPurchaseType : ""}
                fullWidth
                required
                select
              >
                {purchaseType.map((ele: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={ele.value}>
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            ) : (
              <TextField
                label="Address"
                name="Address"
                onChange={handleChangeAddress}
                variant="outlined"
                margin="normal"
                // value={selectedPurchaseType}
                // defaultValue={isProduct ? selectedPurchaseType : ""}
                fullWidth
                required
                select
              >
                {addresses.map((ele: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={ele.address_id}>
                      {ele.address}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}

            {isProduct ? (
              <>
                <TableContainer>
                  <Table aria-label="simple table" sx={{ marginTop: "15px" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "28%", padding: "5px 0px" }}>
                          Product Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "10%", padding: "5px 0px" }}
                        >
                          Quantity (In Coins)
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "5%", padding: "5px 0px" }}
                        >
                          Charges
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedProducts.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              padding: "5px 0px",
                            }}
                          >
                            <Typography> {row.name}</Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: "5px 0px" }}>
                            {" "}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                aria-label="decrement"
                                onClick={() =>
                                  handleQuantity(
                                    row,
                                    row.basePrice,
                                    "decrement"
                                  )
                                }
                                disabled={
                                  row.totalQuantity
                                    ? row.totalQuantity <= 1
                                    : true
                                } // Prevent going below 1
                              >
                                <RemoveIcon />
                              </IconButton>
                              <TextField
                                sx={{ width: "80px", margin: "0 10px" }}
                                value={row.totalQuantity ?? 1}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                inputProps={{ readOnly: true }}
                              />
                              <IconButton
                                aria-label="increment"
                                onClick={() =>
                                  handleQuantity(
                                    row,
                                    row.basePrice,
                                    "increment"
                                  )
                                }
                                disabled={submitClose}
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: "5px 0px" }}>
                            <Typography>
                              {row.totalCharge ?? row.basePrice}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "5px 0px",
                          }}
                        ></TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "5px 0px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <Typography>Total : </Typography>
                          <Typography>{parseFloat(totalCount)}</Typography>
                          <Typography
                            sx={{
                              paddingLeft: "15px",
                            }}
                          >
                            ( {totalGrams}gm )
                          </Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "5px 0px",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography sx={{ color: "red" }}>{countError}</Typography>
              </>
            ) : (
              <>
                {selectedPurchaseType === "quantity" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "rows",
                        gap: "10px",
                      }}
                    >
                      <NumericFormat
                        type="text"
                        label="Redeem Units (in Grams)"
                        name="redeem_units"
                        value={qty}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        error={metalError}
                        helperText={metalErrorMsg || "Multiple of 0.0001 "}
                        required
                        thousandsGroupStyle="lakh"
                        thousandSeparator=","
                        customInput={TextField}
                        decimalScale={4}
                        disabled={isAllUnits}
                      />
                      <TextField
                        label="Approx. Value"
                        value={FormatNumber(totalAmount)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        disabled
                      />
                    </Box>
                    <Checkbox name="checked" onChange={handleCheck} />
                    <span>Redeem entire units</span>
                  </>
                )}

                {selectedPurchaseType === "amount" && (
                  <TextField
                    label="Redeem Amount"
                    name="redeem_amount"
                    value={totalAmount}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={handleChange}
                    error={metalError}
                    helperText={metalErrorMsg || ""}
                  />
                )}
              </>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading || submitClose}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </CardContent>
        <Box sx={{ padding: "10px" }}>
          <Divider variant="middle" sx={{ background: "#8a8686" }} />
          <p
            style={{
              padding: "10px 10px 0 0",
              textAlign: "start",
              fontSize: "16px",
            }}
          >
            * Units can be sold only after 48 hours of purchase.
          </p>
          <p
            style={{
              padding: "4px 10px 0 0",
              textAlign: "start",
              fontSize: "16px",
            }}
          >
            * Current units available for{" "}
            {redeemType === ""
              ? "Selling / Delivery"
              : redeemType === "Sell"
              ? "Selling"
              : redeemType}{" "}
            = {redeemableUnits.toFixed(4)} Grams
          </p>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "0 10px 10px 0",
            }}
          >
            <img src="/PowerdByAugmonte.jpg" width={150} />
          </Box>
        </Box>
        <Snackbar
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => setIsSuccess(false)}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="success">{`Submitted. ${Msg}`}</Alert>
        </Snackbar>
        <Snackbar
          open={isFailure}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="error">{Msg}</Alert>
        </Snackbar>
      </Card>
      {isProduct && (
        <DeliverProducts
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          total={totalCount}
          setCountError={setCountError}
          totalQuantity={totalQuantity}
        />
      )}
    </>
  );
}
