import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";

const SuperAdminPreIpoKycModel = ({
  isKycOpen,
  setIsKycOpen,
  accessToken,
  setIsUpdated,
  setSuccess,
  setError,
  setMsg,
}: any) => {
  const { id } = useParams();

  const [files, setFiles] = useState<any>({
    aadhaar: null,
    pan: null,
    bankProof: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFileChange = (event: any, key: keyof any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles((prev: any) => ({
        ...prev,
        [key]: event.target.files[0],
      }));
    }
  };

  const handleClose = () => {
    setIsKycOpen(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    let formData = new FormData();
    formData.append("doc_aadhar", files.aadhaar);
    formData.append("doc_pan", files.pan);
    formData.append("doc_cancelled_cheque", files.bankProof);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/preIpo/uploadKyc?id=${id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setIsLoading(false);
          setMsg(data.message);
          return;
        }

        setSuccess(true);
        setMsg(data.message);
        handleClose();
        setIsUpdated(true);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        console.log("error from Add KYC  Documents ->", response);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={isKycOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Kyc Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <div>
              <TextField
                label="Aadhaar"
                onChange={(e) => handleFileChange(e, "aadhaar")}
                type="file"
                name="Upload file"
                variant="outlined"
                margin="normal"
                fullWidth
                focused
                required
              ></TextField>
            </div>
            <div>
              <TextField
                label="Pan Card"
                onChange={(e) => handleFileChange(e, "pan")}
                type="file"
                name="Upload file"
                variant="outlined"
                margin="normal"
                fullWidth
                focused
                required
              />
            </div>
            <div>
              <TextField
                label="Cancelled Cheque"
                onChange={(e) => handleFileChange(e, "bankProof")}
                type="file"
                name="Upload file"
                variant="outlined"
                margin="normal"
                fullWidth
                focused
                required
              />
            </div>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                paddingBottom: "20px",
                overflow: "hidden",
              }}
            > */}
            {/* <Box
                sx={{
                  display: "flex",
                  gap: "100px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Aadhaar</Typography>
                <Button
                  component="label"
                  variant="contained"
                >
                  {files.aadhaar ? files.aadhaar.name : "Upload"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => handleFileChange(e, "aadhaar")}
                  />
                </Button>
              </Box> */}

            {/* PAN Card Upload */}
            {/* <Box
                sx={{
                  display: "flex",
                  gap: "100px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>PAN Card</Typography>
                <Button component="label" variant="contained">
                  {files.pan ? files.pan : "Upload"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => handleFileChange(e, "pan")}
                  />
                </Button>
              </Box> */}

            {/* <Box
                sx={{
                  display: "flex",
                  gap: "100px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography> Cancelled Cheque </Typography>
                <Button component="label" variant="contained">
                  {files.bankProof ? files.bankProof : "Upload"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => handleFileChange(e, "bankProof")}
                  />
                </Button>
              </Box> */}
            {/* </Box> */}

            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default SuperAdminPreIpoKycModel;
