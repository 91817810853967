import React, { useEffect, useState } from "react";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import TabsButtons from "../TabsButtons";
import InfoIcon from "@mui/icons-material/Info";
import { FormatNumber } from "../../../utils/formatNumber";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import AccountModal from "./IpoAccountModal";
// import KycModal from "./IpoKycModal";
// import BankModal from "./IpoBankModal";

const IpoInvestment = ({ user, accessToken }) => {
  const navigate = useNavigate();
  const [preIpoProfile, setPreIpoProfile] = useState<any>(null);
  const [investments, setInvestments] = useState<any>(null);

  useEffect(() => {
    fetchPreIpoProfile();
    fetchTotalInvestment();
  }, []);

  const fetchPreIpoProfile = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/profile`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(({ data }) => {
        if (data.succ) {
          setPreIpoProfile(data);
        }
      })
      .catch(({ response }) => {
        console.log("Error from fetch PreIpo Profile ->", response);
      });
  };

  const fetchTotalInvestment = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/dashboard`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(({ data }) => {
        if (data.succ) {
          setInvestments(data.data);
        }
      })
      .catch(({ response }) => {
        console.log("Error from fetch PreIpo Profile ->", response);
      });
  };

  return (
    <>
      <TabsButtons investment={"ipo"} page={"home"} />

      {!investments ? (
        <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={8} md={6} lg={4}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Pre IPO Shares
                    </Typography>
                    {preIpoProfile ? (
                      <Button
                        variant="contained"
                        color="success"
                        // onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        // onClick={() => setOpenAccount(true)}
                        onClick={() => navigate("/dashboardAdmin/profile")}
                      >
                        Create
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              overflow: { xs: "scroll", sm: "hide" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                minWidth: { xs: "50rem", sm: "69vw" },
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="h6"
                >
                  Company Name
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="h6"
                >
                  Units
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="h6"
                >
                  Amount Invested
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="h6"
                >
                  Actions
                </Typography>
              </Grid>
            </Box>
            <Divider
              sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
            />
            {investments.map((row, idx) => {
              return (
                <>
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: { xs: "50rem", sm: "69vw" },
                      overflowX: { xs: "scroll", sm: "hidden" },
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        {row.company_name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                      >
                        {FormatNumber(row.totalUnits)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">
                          {FormatNumber(row.totalInvestment)}
                        </Typography>
                        {/* <Typography
                          variant="caption"
                          color={value.gain < 0 ? "red" : "#32CD32"}
                          sx={{ textAlign: "end" }}
                        >
                          {value.gain < 0
                            ? FormatNumber(value?.gain)
                            : `+ ${FormatNumber(value?.gain)}`}
                        </Typography> */}
                      </div>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* <Grid
                        item
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      > */}
                      <div
                        style={{
                          padding: "10px",
                          width: "100px",
                          borderRadius: "5px",
                          textAlign: "center",
                          color: "white",
                          backgroundColor: "#ffa500",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          navigate(`/dashboardAdmin/apply-ipo`, {
                            state: {
                              isin: row.isin,
                              name: row.company_name,
                            },
                          })
                        }
                      >
                        Buy More
                      </div>
                      {/* </Grid> */}
                    </Grid>
                  </Box>
                  {/* {idx !== investments.length - 1 && ( */}
                  <Divider
                    sx={{
                      mt: 2,
                      mb: 2,
                      minWidth: { xs: "50rem", sm: "69vw" },
                    }}
                  />
                  {/* )} */}
                </>
              );
            })}
          </Box>

          {/* *** Mobile view *** */}
          <Box
            sx={{
              display: {
                xs: "flex",
                flexDirection: "column",
                gap: 3,
                sm: "none",
                p: 0,
              },
            }}
          >
            {investments?.map((row, idx) => {
              return (
                <>
                  <Paper elevation={1} sx={{ padding: 1 }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      gap={1}
                    >
                      <Typography
                        style={{ fontWeight: 600, color: "grey" }}
                        variant="body1"
                      >
                        Company Name
                      </Typography>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        {row.company_name}
                      </Typography>
                    </Grid>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      display="flex"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: 600, color: "grey" }}
                          variant="body1"
                        >
                          Units
                        </Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" alignItems="center">
                        <Typography
                          variant="body1"
                          display="flex"
                          alignItems="center"
                          gap={0.5}
                          style={{ fontWeight: 600 }}
                        >
                          {FormatNumber(row.totalUnits)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: 600, color: "grey" }}
                          variant="body1"
                        >
                          Amount Invested
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ fontWeight: 600 }} variant="body1">
                          {FormatNumber(row.totalInvestment)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Grid item xs={12} display="flex" alignItems="center">
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontWeight: 600, color: "grey" }}
                          variant="body1"
                        >
                          Actions
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            width: "100px",
                            borderRadius: "5px",
                            textAlign: "center",
                            color: "white",
                            backgroundColor: "#ffa500",
                            cursor: "pointer",
                          }}
                          // onClick={(e) =>
                          //   navigate(`/dashboardAdmin/goldInvesting`, {
                          //     state: { key: key, value: value },
                          //   })
                          // }
                        >
                          Buy More
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              );
            })}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default IpoInvestment;
