import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FormatNumber } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import TabsButtons from "../../Admin/TabsButtons";
import InfoIcon from "@mui/icons-material/Info";
import SuperAdminGoldAccountModal from "./SuperAdminGoldMemberAccountModel";
import SuperAdminGoldMemberKycModal from "./SuperAdminGoldMemberKyc";
import SuperAdminGoldMemberBankModal from "./SuperAdminGoldMemberBank";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import PlaceIcon from "@mui/icons-material/Place";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import { fontWeight } from "@mui/joy/styles/styleFunctionSx";
import AddNewBank from "../../Admin/Profile/AddNewBank";
import { error } from "console";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 900,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CreateGoldAccount({ accessToken }) {
  const { id } = useParams();

  const [userData, setUserData] = useState<any>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [openAccount, setOpenAccount] = useState<boolean>(false);
  const [isKycOpen, setIsKycOpen] = useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [storeState, dispatch] = useAppContext();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<any>(null);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);
  const [banks, setBanks] = useState<any>([]);

  const fetchAccountData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/gold-member/profile?id=${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (data.succ) {
          setUserData(data.data);
          setIsUpdated(false);
        }
      })
      .catch((error) => {
        console.log("Error from get user details ->", error);
      });
  };

  const fetchBankData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/gold-member/bankAccounts?id=${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (data.succ) {
          setBanks(data.data);
          setIsUpdated(false);
        }
      })
      .catch((error) => {
        console.log("Error  from get user bank list->", error);
      });
  };

  // console.log("user ->", user);

  useEffect(() => {
    if (isUpdated) {
      fetchAccountData();
      fetchBankData();
    }
  }, [isUpdated]);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const updateBank = async (row: any) => {
    setSelectedBank(row);
    setIsOpen(true);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment={"gold"} page={"createAccount"} />

      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Gold Investment
          </Typography>
          <Grid container spacing={2}>
            {userData?.user ? (
              <Grid xs={11} sm={6} md={4}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "text.secondary",
                        }}
                      >
                        Profile
                      </Typography>
                      <IconButton
                        aria-label="editor"
                        color="primary"
                        onClick={() => setOpenAccount(true)}
                      >
                        <CreateTwoToneIcon sx={{ fontSize: 28 }} />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        marginBottom: 2,
                        marginTop: 3,
                      }}
                    >
                      <PersonRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 30 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {`${userData.user.name} (${userData.user.unique_id})`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <PhoneRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.mobileNumber}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <EmailRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.email ? userData.user.email : "-"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <DateRangeRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.dob
                          ? new Date(userData.user.dob).toDateString()
                          : ""}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <CreditCardRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData?.user?.pan?.panNumber ?? "-"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <PlaceIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData?.address?.address ?? "-"}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid xs={11} sm={6} md={4}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="h6">
                        Create Account
                      </Typography>

                      <Button
                        variant="contained"
                        onClick={() => setOpenAccount(true)}
                      >
                        Pending
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {banks?.length > 0 ? (
              <Grid item xs={12} md={8}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button variant="contained" onClick={() => setIsOpen(true)}>
                    Add
                  </Button>
                </Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Account Name</StyledTableCell>
                        <StyledTableCell align="right">
                          Account Number
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Bank Name
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          IFSC Code
                        </StyledTableCell>
                        <StyledTableCell align="right">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {banks?.length > 0 ? (
                        banks?.map((row: any) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.accountName}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.accountNumber}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.bankName}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.ifscCode}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <IconButton
                                aria-label="editor"
                                onClick={() => updateBank(row)}
                              >
                                <CreateTwoToneIcon />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <StyledTableRow>
                          <Typography component="th" scope="row"></Typography>
                          <Typography component="th" scope="row">
                            Bank are not found
                          </Typography>
                          <Typography component="th" scope="row"></Typography>
                          <Typography component="th" scope="row"></Typography>
                          <Typography component="th" scope="row"></Typography>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ) : (
              <Grid xs={11} sm={6} md={4}>
                <Card sx={{ alignSelf: "start" }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="h6">
                        Bank Details
                      </Typography>

                      <Button
                        variant="contained"
                        onClick={() => setIsOpenBank(true)}
                      >
                        Pending
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid xs={12} md={4} sx={{ marginRight: "5px" }}>
              <Card sx={{ marginTop: { xs: 2, md: 5 } }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      KYC
                    </Typography>
                    {userData?.user?.kyc_status === 0 ? (
                      <Button
                        variant="contained"
                        onClick={() => setIsKycOpen(true)}
                      >
                        Pending
                      </Button>
                    ) : userData?.user?.kyc_status === 1 ? (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setIsKycOpen(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {openAccount && (
        <SuperAdminGoldAccountModal
          openAccount={openAccount}
          setOpenAccount={setOpenAccount}
          accessToken={accessToken}
          // userData={userData?.user ? userData?.user : user}
          userData={userData?.user}
          userAddress={userData?.address}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isKycOpen && (
        <SuperAdminGoldMemberKycModal
          isKycOpen={isKycOpen}
          setIsKycOpen={setIsKycOpen}
          userData={userData?.user}
          panData={
            userData?.user?.pan?.panNumber
            // userData?.user?.pan
            //   ? userData?.user?.pan?.panNumber
            //   : user.panNumber
          }
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isOpenBank && (
        <SuperAdminGoldMemberBankModal
          isOpenBank={isOpenBank}
          setIsOpenBank={setIsOpenBank}
          userData={userData?.user}
          bank={userBank}
          accessToken={accessToken}
          setSuccess={setSuccess}
          setIsUpdated={setIsUpdated}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isOpen && (
        <AddNewBank
          isOpenBank={isOpen}
          setIsOpenBank={setIsOpen}
          bank={selectedBank}
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSelectedBank={setSelectedBank}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
}
