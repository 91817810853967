import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { InCredSalaryRange } from "../../../Data/data";
import { useAppContext } from "../../../Store/AppContext";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";

const SuperAdminIpoAccountModal = ({
  openAccount,
  setOpenIpoAccount,
  accessToken,
  userData,
  userAddress,
  setSuccess,
  setError,
  setMsg,
  setIsUpdated,
}: any) => {
  const { id } = useParams();

  const [panNumber, setPanNumber] = useState(userData?.pan_number ?? "");
  const [name, setName] = useState<string>(userData?.name ?? "");
  const [mobile, setMobile] = useState<string>(userData?.phone_number ?? "");
  const [email, setEmail] = useState<string>(userData?.email ?? "");
  const [income, setIncome] = useState<string>(userData?.income ?? "");
  const genderOptions = ["MALE", "FEMALE", "TRANSGENDER", "OTHER"];

  const [dob, setDob] = useState(
    userData && userData?.birth_date
      ? new Date(userData.birth_date).toISOString().split("T")[0]
      : ""
  );

  const [address_line1, setAddressLine1] = useState<string>(
    userAddress?.correspondance_address.line_1
  );

  const [address_line2, setAddressLine2] = useState<string>(
    userAddress?.correspondance_address.line_2
  );

  const [address_line3, setAddressLine3] = useState<string>(
    userAddress?.correspondance_address.line_3
  );

  const [pincode, setPincode] = useState<string>(
    userAddress?.correspondance_address.pin_code
  );
  const [city, setCity] = useState<string>(
    userAddress?.correspondance_address.city
  );
  const [state, setState] = useState<string>(
    userAddress?.correspondance_address.state
  );
  const [country, setCountry] = useState<string>(
    userAddress?.correspondance_address.country
  );

  const [gender, setGender] = useState(userData?.gender ?? "");
  const [isLoading, setIsLoading] = useState<boolean>();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenIpoAccount(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    const date_of_birth = new Date(dob);
    const date =
      date_of_birth.getDate() < 10
        ? `0${date_of_birth.getDate()}`
        : date_of_birth.getDate();

    const month =
      date_of_birth.getMonth() < 10
        ? `0${date_of_birth.getMonth()}`
        : date_of_birth.getMonth();

    const year = date_of_birth.getFullYear();

    let accountObj = {
      user: {
        user_id: id,
        pan_number: panNumber,
        phone_number: mobile,
        name: name,
        email: email,
        gender: gender,
        birth_date: `${date}/${month}/${year}`,
        income,
      },
      address: {
        line_1: address_line1,
        line_2: address_line2,
        line_3: address_line3,
        city: city,
        state: state,
        country: country,
        pin_code: pincode,
      },
    };

    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/create/preIpo/account`,
        accountObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setIsLoading(false);
          setMsg(data.message);
          return;
        }

        setSuccess(true);
        setMsg(data.message);
        handleClose();
        setIsUpdated(true);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        console.log("error from create account ->", response);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openAccount}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Account Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <TextField
              type="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              fullWidth
              margin="normal"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />

            <Autocomplete
              fullWidth
              freeSolo
              options={genderOptions} // Gender list
              value={gender}
              onChange={(e, value: any) => setGender(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Gender"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              )}
            />

            <Grid container sx={{ marginTop: "15px" }}>
              <Grid xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    onChange={(date: any) => setDob(date)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={false} // Ensure error prop is explicitly set to false
                        helperText="" // Remove any helper text
                        required
                      />
                    )}
                    value={dob}
                    inputFormat="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={6}>
                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Pan Number"
                  variant="outlined"
                  value={panNumber}
                  onChange={(e) => setPanNumber(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <TextField
              label="Income Range"
              name="income"
              onChange={(e) => setIncome(e.target.value)}
              value={income}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              select
            >
              {InCredSalaryRange.map((each, idx) => (
                <MenuItem key={idx} value={each.value}>
                  {each.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              id="outlined-basic"
              label="Address (Line 1)"
              placeholder="Plot No. / Apartment No. / Street Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={address_line1}
              onChange={(e) => setAddressLine1(e.target.value)}
              required
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Locality / Nearest Landmark"
              placeholder="Locality / Nearest Landmark"
              variant="outlined"
              fullWidth
              margin="normal"
              value={address_line2}
              onChange={(e) => setAddressLine2(e.target.value)}
              required
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="City / State / Postal Code"
              variant="outlined"
              placeholder="City / State / Postal Code"
              fullWidth
              margin="normal"
              value={address_line3}
              onChange={(e) => setAddressLine3(e.target.value)}
              required
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Pincode"
              variant="outlined"
              fullWidth
              margin="normal"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              margin="normal"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="State"
              variant="outlined"
              fullWidth
              margin="normal"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Country"
              variant="outlined"
              fullWidth
              margin="normal"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default SuperAdminIpoAccountModal;
