import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { FormatNumber } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import TabsButtons from "../../Admin/TabsButtons";
import InfoIcon from "@mui/icons-material/Info";
import SuperAdminIpoAccountModal from "./SuperAdminPreIpoAccountModel";
import SuperAdminPreIpoKycModel from "./SuperAdminPreIpoKycModel";
import SuperAdminPreIpoBankModel from "./SuperAdminPreIpoBankModel";
import PreIpoProfileCard from "../../Admin/Ipo/PreIpoProfileCard";

export default function SuperAdminPreIpoMemberDetail({ accessToken }) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [investments, setInvestments] = useState<any>(null);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const getTransactionReports = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/preIpo/transactions?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          return;
        }
        setInvestments(data.transactions);
      })
      .catch((error) => {
        setMsg(error);
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    getTransactionReports();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment={"ipo"} page={"view"} />

      <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            overflow: { xs: "scroll", sm: "hide" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              minWidth: { xs: "50rem", sm: "69vw" },
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="h6"
              >
                Company Name
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="h6"
              >
                Units
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="h6"
              >
                Amount Invested
              </Typography>
            </Grid>
          </Box>
          <Divider
            sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
          />
          {investments?.map((row, idx) => {
            return (
              <>
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    minWidth: { xs: "50rem", sm: "69vw" },
                    overflowX: { xs: "scroll", sm: "hidden" },
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="body1">{row.company_name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      display="flex"
                      alignItems="center"
                      gap={0.5}
                    >
                      {FormatNumber(row.totalUnits)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body1">
                        {FormatNumber(row.totalInvestment)}
                      </Typography>
                      {/* <Typography
                                variant="caption"
                                color={value.gain < 0 ? "red" : "#32CD32"}
                                sx={{ textAlign: "end" }}
                              >
                                {value.gain < 0
                                  ? FormatNumber(value?.gain)
                                  : `+ ${FormatNumber(value?.gain)}`}
                              </Typography> */}
                    </div>
                  </Grid>
                </Box>
                <Divider
                  sx={{
                    mt: 2,
                    mb: 2,
                    minWidth: { xs: "50rem", sm: "69vw" },
                  }}
                />
              </>
            );
          })}
        </Box>

        {/* *** Mobile view *** */}
        <Box
          sx={{
            display: {
              xs: "flex",
              flexDirection: "column",
              gap: 3,
              sm: "none",
              p: 0,
            },
          }}
        >
          {investments?.map((row, idx) => {
            return (
              <>
                <Paper elevation={1} sx={{ padding: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    gap={1}
                  >
                    <Typography
                      style={{ fontWeight: 600, color: "grey" }}
                      variant="body1"
                    >
                      Company Name
                    </Typography>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      {row.company_name}
                    </Typography>
                  </Grid>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: 600, color: "grey" }}
                        variant="body1"
                      >
                        Units
                      </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                        style={{ fontWeight: 600 }}
                      >
                        {FormatNumber(row.totalUnits)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Grid item xs={12} display="flex" alignItems="center" gap={2}>
                    <Grid item xs={6}>
                      <Typography
                        style={{ fontWeight: 600, color: "grey" }}
                        variant="body1"
                      >
                        Amount Invested
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        {FormatNumber(row.totalInvestment)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Paper>
              </>
            );
          })}
        </Box>
      </Paper>

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
}
