import { useTheme } from "@emotion/react";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { Fragment, useState } from "react";
import { assetType } from "../../../Data/data";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddNewOtherInvestment = ({
  accessToken,
  isOpen,
  setIsOpen,
  setSelectedInvestment,
  user_id,
  setIsUpdate,
  setSuccess,
  setError,
  setMsg,
  selectedInvestment,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any>(
    selectedInvestment?.attachments ?? null
  );
  const [selectedType, setSelectedType] = useState<string>(
    selectedInvestment?.assetType ?? null
  );
  const [issuer, setIssuer] = useState<string>(
    selectedInvestment?.issuer ?? null
  );

  const [instrument, setInstrument] = useState<string>(
    selectedInvestment?.instrument ?? null
  );

  const [amount, setAmount] = useState<string>(
    selectedInvestment?.investedAmount ?? null
  );
  const [currentValue, setCurrentValue] = useState<string>(
    selectedInvestment?.currentValue ?? null
  );
  const [date, setDate] = useState<string>(selectedInvestment?.date ?? null);

  const changeHandler = (value: any) => {
    setSelectedType(value);
  };

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    let body = {
      date,
      user_id,
      assetType: selectedType,
      issuer,
      instrument,
      investedAmount:
        typeof amount === "string" ? Number(amount.replace(/,/g, "")) : amount,
      currentValue:
        currentValue === "" || !currentValue
          ? null
          : typeof currentValue === "string"
          ? Number(currentValue.replace(/,/g, ""))
          : currentValue,

      attachments: file,
    };

    if (selectedInvestment) {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_HOST}v1/super/otherInvestments`,
          { ...body, id: selectedInvestment._id },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(({ data }) => {
          if (!data.succ) {
            setError(true);
            setMsg(data.message);
            return;
          }
          setIsOpen(false);

          setIsUpdate(true);
          setSuccess(true);
          setMsg(data.message);
          setSelectedInvestment(null);
        })
        .catch((error) => {
          console.log("error from add other Investment ->", error);
        });
    } else {
      let bodyObj: any = {};

      if (file) {
        bodyObj = new FormData();

        Object.entries(body).forEach(([key, value]) => {
          bodyObj.append(key, value);
        });

        body = bodyObj;
      }

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/super/otherInvestments/addInvestment`,
          body,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(({ data }) => {
          if (!data.succ) {
            setError(true);
            setMsg(data.message);
            return;
          }
          setIsOpen(false);

          setIsUpdate(true);
          setSuccess(true);
          setMsg(data.message);
          setSelectedInvestment(null);
        })
        .catch((error) => {
          console.log("error from add other Investment ->", error);
        });
    }

    setIsLoading(false);
  };

  return (
    <Fragment>
      <Dialog
        // fullScreen={"md"}
        open={isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            // Prevent closing when clicking outside or pressing Escape
            return;
          }
          setIsOpen(false);
        }}
        // onClose={() => setIsOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {selectedInvestment ? "Update Investments" : "New Investments"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                onChange={(date: any) => setDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={false} // Ensure error prop is explicitly set to false
                    helperText="" // Remove any helper text
                    margin="normal"
                    // required
                    fullWidth
                  />
                )}
                value={date}
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <TextField
              value={selectedType}
              required
              onChange={(e) => {
                changeHandler(e.target.value);
              }}
              select
              type="text"
              id="outlined-basic"
              label="Asset Type"
              variant="outlined"
              defaultValue="once"
              margin="normal"
              fullWidth
              // helperText="Please select your currency"
              //   style={{ width: "100%" }}
            >
              {assetType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              type="text"
              id="outlined-basic"
              label="Enter issuer"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={issuer}
              onChange={(e) => setIssuer(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Enter Instrument"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={instrument}
              onChange={(e) => setInstrument(e.target.value)}
            />

            <NumericFormat
              type="text"
              label="Amount"
              name="view_amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              thousandsGroupStyle="lakh"
              thousandSeparator=","
              customInput={TextField}
              decimalScale={2}
            />

            <NumericFormat
              type="text"
              label="Current Value"
              name="current_amount"
              value={currentValue}
              onChange={(e) => setCurrentValue(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              thousandsGroupStyle="lakh"
              thousandSeparator=","
              customInput={TextField}
              decimalScale={2}
            />

            {!selectedInvestment && (
              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <TextField
                  label="Upload file"
                  onChange={(e) => handleFileChange(e)}
                  type="file"
                  name="Upload file"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  focused
                  // required
                />
              </div>
            )}

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsOpen(false)}
              >
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AddNewOtherInvestment;
