import {
  Box,
  Modal,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    fontWeight: "bolt",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ViewPriceList = ({
  isPriceSlabOpen,
  setIsPriceSlabOpen,
  price,
  token,
  date,
  isin,
  //   unit,
}) => {
  const [priceList, setPriceList] = useState<any>([]);

  const getProductPriceList = () => {
    // setSelectedScheme(fundType);
    // setIsLOading(true);

    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          `v1/preIpo/product/price?isin=${isin}&date=${date}&units=${1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log("Price list ->", res.data?.data);

        setPriceList(res.data.data);
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };

  useEffect(() => {
    getProductPriceList();
  }, []);

  return (
    <Modal
      open={isPriceSlabOpen}
      onClose={(e: any) => setIsPriceSlabOpen(false)}
      aria-labelledby="price-range-modal-title"
      aria-describedby="price-range-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, md: 400 },
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h5 style={{ textAlign: "center", paddingBottom: "15px" }}>
          Get additional discount on purchasing higher number of units
        </h5>
        <Table
          sx={{
            width: "100%",
            border: "1px solid #e6e6e6",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          aria-label="customized table"
        >
          <TableHead sx={{}}>
            <TableRow sx={{ backgroundColor: "#397af5" }}>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceList?.staggered_price?.length > 0 ? (
              priceList.staggered_price.map((row: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.from}-{row.to}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      ₹ {parseFloat(price) - parseFloat(price) * row.discount}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  component="th"
                  scope="row"
                  colSpan={2}
                >
                  Price are not found.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Modal>
  );
};

export default ViewPriceList;
