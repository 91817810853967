import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./../../../styles/invoice.css"; // External CSS for styling

import "rsuite/dist/rsuite.min.css";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { FormatNumber, FormatNumberRedeem } from "../../../utils/formatNumber";
import Loading from "../../Dashboard/Loading";
import Datefilter from "../../../utils/Datefilter";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReplayIcon from "@mui/icons-material/Replay";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

export default function TransactionDateWise({ accessToken }: any) {
  const [tranx, setTranx] = useState<any>([]);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  let transactionId = "";
  let isInvoiceLoader = false;

  const handleDownload = async (id, fileName) => {
    // Set loading state for this specific transaction

    transactionId = id;
    isInvoiceLoader = true;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/invoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      );

      const { data } = response;

      // Check if the response is a valid PDF
      if (data.type !== "application/pdf") {
        const text = await new Response(data).text();
        const parsedError = JSON.parse(text);
        if (!parsedError.succ) {
          setError(true);
          setMessage(parsedError.message || "Failed to download file.");
          return;
        }
      }

      // Create a blob URL and trigger download
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
      setError(true);
      setMessage("Failed to download file.");
    } finally {
      transactionId = id;
      isInvoiceLoader = false;
    }
  };

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      renderHeader: () => <strong>{"S.No"}</strong>,
    },
    {
      field: "metalType",
      headerName: "Gold / Silver",
      width: 120,
      renderHeader: () => <strong>{"Metal"}</strong>,
      renderCell: (params) => {
        let { metalType } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {metalType.charAt(0).toUpperCase() + metalType.slice(1)}
          </div>
        );
      },
    },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      width: 300,
      renderHeader: () => <strong>{"Transaction ID"}</strong>,
      renderCell: (params) => {
        let { transaction_id } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {transaction_id}
          </div>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 200,
      renderHeader: () => <strong>{"Transaction Type"}</strong>,
      renderCell: (params) => {
        let { transaction_type, status } = params.row;
        // const TransactionType = transaction_type.includes("Purchase") ? "Purchase" : transaction_type.includes("Redemption") ? "Redemption" : "";

        transaction_type =
          transaction_type === "Gift" &&
          (status === "Sent" || status === "Received")
            ? `${transaction_type} (${status})`
            : transaction_type;

        let statusColor = "green";

        if (status === "Pending") statusColor = "#FDD017";
        else if (status === "Cancel") statusColor = "red";

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{transaction_type}</Typography>
            <Typography
              sx={{ color: statusColor, textWrap: "wrap" }}
              variant="caption"
            >
              {status}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "Total Grams",
      headerName: "Total Grams",
      width: 180,
      renderHeader: () => <strong>{"Total Grams"}</strong>,
      renderCell: (params) => {
        const { transaction_type, status, receive_quantity, sell_quantity } =
          params.row;

        const units =
          transaction_type === "Buy" ||
          (transaction_type === "Gift" && status === "Received")
            ? receive_quantity
            : sell_quantity;

        return <Typography variant="body1">{units}</Typography>;
      },
    },
    {
      field: "Total Amount",
      headerName: "Total Amount",
      width: 180,
      renderHeader: () => <strong>{"Total Amount"}</strong>,
      renderCell: (params) => {
        const { totalAmount } = params.row;

        return (
          <Typography variant="body1">
            {totalAmount ? `Rs. ${FormatNumber(totalAmount)}` : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "settlement",
      headerName: "Settlement",
      width: 180,
      renderHeader: () => <strong>{"Settlement"}</strong>,
      renderCell: (params) => {
        const { transaction_type, settlement_status } = params.row;

        let settlementStatusColor = "green";

        if (settlement_status === "Pending") settlementStatusColor = "#FDD017";
        else if (
          settlement_status === "Rejected" ||
          settlement_status === "Cancel"
        )
          settlementStatusColor = "red";
        return (
          <div>
            {transaction_type === "Sell" && (
              <div
                style={{
                  minHeight: "50px",
                  alignItems: "center",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center", // Center horizontally
                  color: settlementStatusColor,
                  gap: "5px",
                }}
              >
                {settlement_status}
                {settlement_status === "Rejected" && (
                  <ReplayIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsOpenBank(true)}
                  />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Transaction Date",
      width: 240,
      renderHeader: () => <strong>{"Transaction Date"}</strong>,
      renderCell: (params) => {
        let { transaction_date } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center", // Center horizontally
            }}
          >
            {moment(transaction_date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      field: "invoice",
      headerName: "Invoice",
      // width: 180,
      renderHeader: () => <strong>{"Invoice"}</strong>,
      renderCell: (params) => {
        const { transaction_type, transaction_id, status } = params.row;

        return (
          <div>
            {((transaction_type === "Buy" && status === "Success") ||
              (transaction_type === "Gift" && status === "Sent") ||
              (transaction_type === "Deliver" &&
                status !== "Cancel" &&
                status !== "Pending")) && (
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  handleDownload(transaction_id, `${transaction_id}.pdf`)
                }
                disabled={
                  transactionId === transaction_id && isInvoiceLoader
                    ? true
                    : false
                }
              >
                {transactionId === transaction_id && isInvoiceLoader ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CloudDownloadOutlinedIcon
                    style={{ cursor: "pointer", fontSize: "24px" }}
                  />
                )}
              </Button>
            )}
          </div>
        );
      },
    },
  ]);

  const [loading, setLoading] = useState(false);

  const dateConverter = (str) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(str);
    var mnth = date.getMonth();
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  };

  const [isDisable, SetisDisable] = useState(true);

  const [IntervalDate, setIntervalDate] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const [Interval, setInterval] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const [accountName, setAccountName] = useState<string>("");

  const [account, setAccount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [ifsc, setIfsc] = useState<string>("");

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getTranxData = async () => {
    const startDate = new Date(IntervalDate.startDate).getTime();
    const endDate = new Date(IntervalDate.endDate).getTime();
    const diff = endDate - startDate;
    const ThirtyDays = 1000 * 60 * 60 * 24 * 30;
    if (diff > ThirtyDays) {
      setLoading(false);
      setMessage("Difference between the dates cannot be more than 30 days");
      setError(true);
      return;
    }

    // Adjust the end date to the end of the selected day
    const endOfDay = new Date(IntervalDate.endDate);
    endOfDay.setHours(23, 59, 59, 999);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/transactions`,
        {
          from_date: new Date(IntervalDate.startDate),
          to_date: endOfDay,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const { data } = res;
        setLoading(false);

        if (!data.succ) {
          setError(true);
          setMessage(data.message);

          setTranx(data.data);
          return;
        }
        setTranx(data.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setTranx([]);
        setMessage("Failed to Fetching Data from Server");
        return;
      });

    SetisDisable(true);
  };

  useEffect(() => {
    const orderId = localStorage.getItem("order_id");

    setLoading(true);

    setTimeout(() => {
      if (orderId !== undefined && orderId !== null) {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_HOST}v1/gold/buyOrder?order_id=${orderId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((res) => {
            const { data } = res;

            if (!data.succ) {
              setError(true);
              setMessage(data.message);
              return;
            }

            setSuccess(true);
            setMessage(data.message);
          })
          .catch((error) => {
            console.log("error from get gold order->", error);
            setMessage(error);
            return;
          });
        localStorage.removeItem("order_id");
      }

      // setIsLoading(true);
      getTranxData();
    }, 2000);

    // else {
    //   getTranxData();
    // }
  }, []);

  const changeHandler = (name, value) => {
    setIntervalDate((prev) => ({
      ...prev,
      [name]: dateConverter(value),
    }));
  };

  const handleClose = () => {
    setIsOpenBank(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    let bankObj = {
      accountNumber: account,
      accountName: accountName,
      ifscCode: ifsc,
    };

    setIsLoading(true);

    try {
      let response = await axios.put(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/settlement`,
        bankObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.succ) {
        setSuccess(true);
        setMessage(response.data.message);
        handleClose();
      } else {
        console.log("Account Details Error ->", response.data.message);

        setError(true);
        setMessage(response.data.message);
        handleClose();
      }
    } catch (error) {
      console.log("Account Details Error ->", error);
      // setError(true);
      // setMessage(error);
    }

    setAccountName("");
    setAccount("");
    setIfsc("");
    setIsLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={2} xs>
          <Grid item xs={12} sx={{ ml: { xs: 0, md: 4 }, height: "100vh" }}>
            <Snackbar
              open={isError}
              autoHideDuration={6000}
              onClose={() => setError(false)}
            >
              <Alert severity="error">{message}</Alert>
            </Snackbar>
            <Snackbar
              open={isSuccess}
              autoHideDuration={6000}
              onClose={() => setSuccess(false)}
            >
              <Alert severity="success">{message}</Alert>
            </Snackbar>
            <AppBar
              sx={{
                backgroundColor: "white",
                marginLeft: { xs: "5%", md: 0 },
                display: "flex",
                width: { xs: "96vw", md: "81.5vw" },
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              position="static"
              elevation={0}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  padding: 0,
                }}
              >
                <Datefilter
                  name="startDate"
                  label="From Date"
                  value={IntervalDate.startDate}
                  changeHandler={changeHandler}
                  maxDate={Interval.endDate}
                  minDate={Interval.startDate}
                  inputFormat="dd-MMM-yyyy"
                />
                <Datefilter
                  name="endDate"
                  label="To Date"
                  value={IntervalDate.endDate}
                  changeHandler={changeHandler}
                  maxDate={Interval.endDate}
                  minDate={Interval.startDate}
                  inputFormat="dd-MMM-yyyy"
                />
                <Button
                  sx={{ marginTop: "-3px", height: "37px" }}
                  variant="contained"
                  color="primary"
                  onClick={getTranxData}
                >
                  Search
                </Button>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                height: "85vh",
                display: { xs: "none", sm: "block" },
                width: { xs: "96.5vw", md: "80vw" },
                ml: { xs: 3, sm: 0 },
              }}
            >
              <DataGrid
                sx={{ mt: 1 }}
                getRowHeight={() => "auto"}
                //  hideFooter={true}
                rowsPerPageOptions={[50, 100, 1000]}
                // rows={rows}
                rows={tranx?.map((each: any, idx: number) => {
                  return { ...each, id: idx + 1 };
                })}
                columns={columns.map((each, idx) => {
                  return {
                    id: idx + 1,
                    ...each,
                    headerAlign: "center",
                    align: "center",
                    sx: {
                      size: 2,
                    },
                  };
                })}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {isOpenBank && (
        <Fragment>
          <Dialog
            fullScreen={fullScreen}
            open={isOpenBank}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Add Bank Details
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmitData}>
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Account Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={account}
                  required
                  error={account.length > 15 ? true : false}
                  helperText={
                    account.length > 15
                      ? "Account number must be less than 15 digits."
                      : ""
                  }
                  onChange={(e) => setAccount(e.target.value)}
                />

                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Account Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={accountName}
                  required
                  onChange={(e) => setAccountName(e.target.value)}
                />

                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Ifac Code"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={ifsc}
                  required
                  onChange={(e) => setIfsc(e.target.value)}
                />
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  )}
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Fragment>
      )}
    </>
  );
}
