import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { FormatNumber } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import TabsButtons from "../TabsButtons";
import { DataGrid } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import moment from "moment";

export default function ViewOtherInvestments({ accessToken }) {
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");
  const [isUpdate, setIsUpdate] = useState<boolean>(true);

  const [investments, setInvestments] = useState<any>([]);

  useEffect(() => {
    getTransactionReports();
  }, []);

  const getTransactionReports = async () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/other/investments`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          return;
        }

        setInvestments(data.data);
        setIsUpdate(false);
      })
      .catch((error) => {
        setMsg(error);
        return;
      });

    setLoading(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      renderHeader: () => <strong>{"S.No"}</strong>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 140,
      renderHeader: () => <strong>{"Date"}</strong>,
      renderCell: (params) => {
        let { date } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "start", // Center text inside the div
            }}
          >
            {date ? moment(date).format("DD/MM/YYYY") : ""}
          </div>
        );
      },
    },
    {
      field: "assetType",
      headerName: "Asset",
      width: 180,
      renderHeader: () => <strong>{"Asset"}</strong>,
      renderCell: (params) => {
        let { assetType } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {assetType}
          </div>
        );
      },
    },
    {
      field: "issuer",
      headerName: "Issuer",
      width: 200,
      renderHeader: () => <strong>{"Issuer"}</strong>,
      renderCell: (params) => {
        let { issuer } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {issuer}
          </div>
        );
      },
    },
    {
      field: "instrument",
      headerName: "Instrument",
      width: 450,
      renderHeader: () => <strong>{"Instrument"}</strong>,
      renderCell: (params) => {
        let { instrument } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {instrument}
          </div>
        );
      },
    },
    {
      field: "investedAmount",
      headerName: "Amount Invested (INR)",
      width: 180,
      renderHeader: () => <strong>{"Amount Invested (INR)"}</strong>,
      renderCell: (params) => {
        const { investedAmount } = params.row;

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {FormatNumber(investedAmount)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "currentValue",
      headerName: "Current Value (INR)",
      width: 180,
      renderHeader: () => <strong>{"Current Value (INR)"}</strong>,
      renderCell: (params) => {
        const { currentValue, gain } = params.row;

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">
                  {currentValue !== null ? FormatNumber(currentValue) : "N/A"}
                </Typography>
                {currentValue !== null && (
                  <Typography
                    variant="caption"
                    color={gain < 0 ? "red" : "#32CD32"}
                    sx={{ textAlign: "end" }}
                  >
                    {gain < 0 ? FormatNumber(gain) : `+ ${FormatNumber(gain)}`}
                  </Typography>
                )}
              </div>
            </Grid>
          </Box>
        );
      },
    },
    {
      field: "attachments",
      headerName: "Attachment",
      width: 180,
      renderHeader: () => <strong>{"Attachment"}</strong>,
      renderCell: (params) => {
        const { _id, attachments } = params.row;

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            {attachments && (
              <Button variant="contained" size="small">
                <CloudDownloadOutlinedIcon
                  // color="primary"
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={() => {
                    handleDownloadFile(_id, attachments);
                  }}
                />
              </Button>
            )}
          </Grid>
        );
      },
    },
  ]);

  const handleDownloadFile = async (transactionId, file) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/other/download/documents?docType=attachment&transactionId=${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      );

      if (response.data.type !== "application/pdf") {
        const text = await new Response(response.data).text();
        const parsedError = JSON.parse(text);
        if (!parsedError.succ) {
          setError(true);
          setMsg(parsedError.message || "Failed to download file.");
          return;
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.originalname);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Failed to download file.");
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment={"other"} page={"home"} />

      <Box
        sx={{
          height: "85vh",
          display: { xs: "none", sm: "block" },
          width: "100%",
          ml: { xs: 3, sm: 0 },
        }}
      >
        <DataGrid
          sx={{ width: "98%", margin: "0 auto" }}
          getRowHeight={() => "auto"}
          //  hideFooter={true}
          rowsPerPageOptions={[50, 100, 1000]}
          // rows={rows}
          rows={investments?.map((each: any, idx: number) => {
            return { ...each, id: idx + 1 };
          })}
          columns={columns.map((each, idx) => {
            return {
              id: idx + 1,
              ...each,
              headerAlign: "center",
              align: "center",
              sx: {
                size: 2,
              },
            };
          })}
        />
      </Box>
    </>
  );
}
