import React, { useEffect, useState } from "react";
import TabsButtons from "../../Admin/TabsButtons";
import AddNewOtherInvestment from "./AddNewOtherInvestment";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";

const CreateOtherAccount = ({ accessToken }) => {
  const { id } = useParams();

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");
  const [account, setAccount] = useState<any>(null);

  const [files, setFiles] = useState<any>({
    pan: null,
    bankProof: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAccount();
  }, []);

  const fetchAccount = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/otherInvestments/account?id=${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setIsLoading(false);
          setMsg(data.message);
          return;
        }
        setAccount(data.data);
        setIsLoading(false);
      });
  };

  const handleFileChange = (event: any, key: keyof any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles((prev: any) => ({
        ...prev,
        [key]: event.target.files[0],
      }));
    }
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    let formData = new FormData();
    formData.append("doc_pan", files.pan);
    formData.append("doc_cancelled_cheque", files.bankProof);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/otherInvestments/create/account?id=${id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setIsLoading(false);
          setMsg(data.message);
          return;
        }
        setSuccess(true);
        setMsg(data.message);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        console.log("error from Add KYC  Documents ->", response);
        setIsLoading(false);
      });
  };

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const handleDownloadFile = async (file, type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/download/documents?id=${id}&docType=${type}`,
        // { user_id: id, path: "" },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      );

      if (response.data.type !== "application/pdf") {
        const text = await new Response(response.data).text();
        const parsedError = JSON.parse(text);
        if (!parsedError.succ) {
          setError(true);
          setMsg(parsedError.message || "Failed to download file.");
          return;
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.originalname);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Failed to download file.");
    }
  };

  return (
    <>
      <TabsButtons investment={"other"} page={"createAccount"} />
      <Grid container>
        <Grid md={5}>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmitData}>
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  <TextField
                    label="Pan Card"
                    onChange={(e) => handleFileChange(e, "pan")}
                    type="file"
                    name="Upload file"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    focused
                    required
                  />
                  {account && (
                    <CloudDownloadOutlinedIcon
                      color="primary"
                      style={{ cursor: "pointer", fontSize: "26px" }}
                      onClick={() => {
                        handleDownloadFile(account?.pan_attachment, "pan");
                      }}
                    />
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  <TextField
                    label="Cancelled Cheque"
                    onChange={(e) => handleFileChange(e, "bankProof")}
                    type="file"
                    name="Upload file"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    focused
                    required
                  />
                  {account && (
                    <CloudDownloadOutlinedIcon
                      color="primary"
                      style={{ cursor: "pointer", fontSize: "26px" }}
                      onClick={() => {
                        handleDownloadFile(
                          account?.cancelled_cheque_attachment,
                          "cheque"
                        );
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                  }}
                >
                  <Button type="submit" variant="contained">
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
};

export default CreateOtherAccount;
