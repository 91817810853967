import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./../../../styles/invoice.css"; // External CSS for styling

import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { FormatNumber, FormatNumberRedeem } from "../../../utils/formatNumber";
import Loading from "../../Dashboard/Loading";
import Datefilter from "../../../utils/Datefilter";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReplayIcon from "@mui/icons-material/Replay";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function PreIpoTransactions({ accessToken }: any) {
  const [tranx, setTranx] = useState<any>([]);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const { id } = useParams();

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      renderHeader: () => <strong>{"S.No"}</strong>,
    },
    {
      field: "trade_date",
      headerName: "Trade Date",
      width: 240,
      renderHeader: () => <strong>{"Trade Date"}</strong>,
      renderCell: (params) => {
        let { items } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "start", // Center text inside the div
            }}
          >
            {moment(items[0].trade_date).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      field: "settlementDate",
      headerName: "Settlement Date",
      width: 240,
      renderHeader: () => <strong>{"Settlement Date"}</strong>,
      renderCell: (params) => {
        let { items } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "start", // Center text inside the div
            }}
          >
            {moment(items[0].metadata.price.settlementDate).format(
              "DD/MM/YYYY"
            )}
          </div>
        );
      },
    },
    {
      field: "Name",
      headerName: "Name",
      width: 380,
      renderHeader: () => <strong>{"Name"}</strong>,
      renderCell: (params) => {
        let { items } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {items[0].name}
          </div>
        );
      },
    },
    {
      field: "UNITS",
      headerName: "Amount",
      width: 180,
      renderHeader: () => <strong>{"Units"}</strong>,
      renderCell: (params) => {
        const { items } = params.row;

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{items[0].units}</Typography>
          </Box>
        );
      },
    },
    {
      field: "Price Per Unit",
      headerName: "Price Per Unit",
      width: 180,
      renderHeader: () => <strong>{"Price Per Unit"}</strong>,
      renderCell: (params) => {
        const { items } = params.row;

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{items[0].unit_price}</Typography>
          </Box>
        );
      },
    },
    {
      field: "Total Amount",
      headerName: "Total Amount",
      width: 240,
      renderHeader: () => <strong>{"Total Amount"}</strong>,
      renderCell: (params) => {
        const { amount } = params.row;

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{amount}</Typography>
          </Box>
        );
      },
    },
    {
      field: "Order Status",
      headerName: "Order Status",
      // width: 180,
      renderHeader: () => <strong>{"Order Status"}</strong>,
      renderCell: (params) => {
        const { status } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "start", // Center horizontally
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      field: "Payment Status",
      headerName: "Payment Status",
      width: 240,
      renderHeader: () => <strong>{"Payment Status"}</strong>,
      renderCell: (params) => {
        let { payment } = params.row;

        return (
          <div
            style={{
              minHeight: "50px",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center", // Center horizontally
            }}
          >
            {payment.status ?? "PENDING"}
          </div>
        );
      },
    },
  ]);

  const [loading, setLoading] = useState(false);

  //   const dateConverter = (str) => {
  //     const month = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];
  //     var date = new Date(str);
  //     var mnth = date.getMonth();
  //     var day = ("0" + date.getDate()).slice(-2);
  //     var year = date.getFullYear();
  //     return `${day}-${month[mnth]}-${year}`;
  //   };

  //   const [IntervalDate, setIntervalDate] = useState({
  //     startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
  //     endDate: dateConverter(new Date().getTime()),
  //   });

  //   const [Interval, setInterval] = useState({
  //     startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
  //     endDate: dateConverter(new Date().getTime()),
  //   });

  const getTranxData = async () => {
    // const startDate = new Date(IntervalDate.startDate).getTime();
    // const endDate = new Date(IntervalDate.endDate).getTime();
    // const diff = endDate - startDate;
    // const ThirtyDays = 1000 * 60 * 60 * 24 * 30;
    // if (diff > ThirtyDays) {
    //   setLoading(false);
    //   setMessage("Difference between the dates cannot be more than 30 days");
    //   setError(true);
    //   return;
    // }

    // Adjust the end date to the end of the selected day
    // const endOfDay = new Date(IntervalDate.endDate);
    // endOfDay.setHours(23, 59, 59, 999);

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/preIpo/dateWiseTransactions?id=${id}&limit=10&page=1`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const { data } = res;
        setLoading(false);

        if (!data.succ) {
          setError(true);
          setMessage(data.message);
          setTranx([]);
          return;
        }

        setTranx(data.data.collection);
      })
      .catch((error) => {
        console.log("error from get pre ipo transaction api ->", error);

        setLoading(false);
        setError(true);
        setTranx([]);
        setMessage("Failed to Fetching Data from Server");
        return;
      });
  };

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      // setIsLoading(true);
      getTranxData();
    }, 2000);
  }, []);

  //   const changeHandler = (name, value) => {
  //     setIntervalDate((prev) => ({
  //       ...prev,
  //       [name]: dateConverter(value),
  //     }));
  //   };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={2} xs>
          <Grid item xs={12} sx={{ ml: { xs: 0, md: 4 }, height: "100vh" }}>
            <Snackbar
              open={isError}
              autoHideDuration={6000}
              onClose={() => setError(false)}
            >
              <Alert severity="error">{message}</Alert>
            </Snackbar>
            <Snackbar
              open={isSuccess}
              autoHideDuration={6000}
              onClose={() => setSuccess(false)}
            >
              <Alert severity="success">{message}</Alert>
            </Snackbar>
            <AppBar
              sx={{
                backgroundColor: "white",
                marginLeft: { xs: "5%", md: 0 },
                display: "flex",
                width: { xs: "96vw", md: "81.5vw" },
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              position="static"
              elevation={0}
            >
              {/* <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  padding: 0,
                }}
              >
                <Datefilter
                  name="startDate"
                  label="From Date"
                  value={IntervalDate.startDate}
                  changeHandler={changeHandler}
                  maxDate={Interval.endDate}
                  minDate={Interval.startDate}
                  inputFormat="dd-MMM-yyyy"
                />
                <Datefilter
                  name="endDate"
                  label="To Date"
                  value={IntervalDate.endDate}
                  changeHandler={changeHandler}
                  maxDate={Interval.endDate}
                  minDate={Interval.startDate}
                  inputFormat="dd-MMM-yyyy"
                />
                <Button
                  sx={{ marginTop: "-3px", height: "37px" }}
                  variant="contained"
                  color="primary"
                  onClick={getTranxData}
                >
                  Search
                </Button>
              </Toolbar> */}
            </AppBar>
            <Box
              sx={{
                height: "85vh",
                display: { xs: "none", sm: "block" },
                width: { xs: "96.5vw", md: "80vw" },
                ml: { xs: 3, sm: 0 },
              }}
            >
              <DataGrid
                sx={{ mt: 1 }}
                getRowHeight={() => "auto"}
                //  hideFooter={true}
                rowsPerPageOptions={[50, 100, 1000]}
                // rows={rows}
                rows={tranx?.map((each: any, idx: number) => {
                  return { ...each, id: idx + 1 };
                })}
                columns={columns.map((each, idx) => {
                  return {
                    id: idx + 1,
                    ...each,
                    headerAlign: "center",
                    align: "center",
                    sx: {
                      size: 2,
                    },
                  };
                })}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
