import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { SocketContext } from "../../../Store/SocketContext";
import {
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
  Snackbar,
  Alert,
  Tooltip,
  Grid,
} from "@mui/material";
import { metalType, purchaseType } from "../../../Data/data";
import { load } from "@cashfreepayments/cashfree-js";
import TabsButtons from "../TabsButtons";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

const BuyGoldSilver = ({ user, accessToken }) => {
  const { state }: any = useLocation();

  const { socketData } = useContext(SocketContext);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState<string>("");
  const [price, setPrice] = useState<string>("0");
  const [type, setType] = useState<string>("");
  const [qty, setQty] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [investAmount, setInvestAmount] = useState<number>(0);
  const [cashFree, setCashFree] = useState<any>(null);
  // const [lockPrice, setLockPrice] = useState<any>("");
  // const [blockId, setBlockId] = useState<string>("");
  const [gstAmount, setGstAmount] = useState<number>(0);
  const [paymentCharges, setPaymentCharges] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [timeRemaining, setTimeRemaining] = useState(4 * 60); // Convert minutes to seconds
  // const [storeSocketData, setSocketData] = useState<any>();
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [submitClose, setSubmitClose] = useState<boolean>(false);
  const [isFailure, setIsFailure] = useState(false);
  const [Msg, setMsg] = useState("");
  const [receiver, setReceiver] = useState<{
    uniqueId: string;
    name: string;
    mobileNumber: string;
  }>({
    uniqueId: "",
    name: "",
    mobileNumber: "",
  });

  const [uniqueIdError, setUniqueIdError] = useState<boolean>(false);
  const [UserUniqueErrorMsg, setUserUniqueErrorMsg] = useState<string>("");

  const handleChangePurchaseType = (e: any) => {
    let { value } = e.target;
    setSelectedPurchaseType(value);
    setQty(0);
    setGstAmount(0);
    setInvestAmount(0);
    setTotalAmount(0);
    setPaymentCharges(0);
  };

  useEffect(() => {
    const now = new Date();
    const currentMinutes = now.getMinutes();

    const nextTargetMinute = Math.ceil((currentMinutes + 1) / 4) * 4;
    const targetTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      nextTargetMinute,
      0
    );

    const duration = Math.floor((targetTime.getTime() - now.getTime()) / 1000); // Convert ms to seconds

    setTimeRemaining(duration); // Set initial time

    if (duration < 1) {
      const initialPrice =
        type === "gold" ? socketData?.gBuy : socketData?.sBuy;
      if (initialPrice) {
        localStorage.setItem("metalPrice", initialPrice);
        localStorage.setItem("block_id", socketData?.block_id);
        setPrice(initialPrice);
        if (selectedPurchaseType === "quantity" && qty > 0) {
          setInvestAmount(
            +parseFloat(
              type === "gold" ? socketData?.gBuy : socketData?.sBuy
            ).toFixed(2) * qty
          );
        }
      }
    }
  }, [socketData]);

  useEffect(() => {
    if (!state) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/profile`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(({ data }) => {
          if (!data.succ) {
            return;
          }

          const user = data.data.user;

          setReceiver({
            uniqueId: user.unique_id,
            name: user.name,
            mobileNumber: user.mobileNumber,
          });

          setUserUniqueErrorMsg(`${user.name} - ${user.mobileNumber}`);
        });
    }

    if (state && state.user) {
      setReceiver({
        uniqueId: state.user.unique_id,
        name: state.user.name,
        mobileNumber: state.user.mobileNumber,
      });

      setUserUniqueErrorMsg(`${state.user.name} - ${state.user.mobileNumber}`);
    }
  }, []);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const handleChangeAmount = (e: any) => {
    let { value } = e.target;

    switch (selectedPurchaseType) {
      case "quantity":
        if (/^\d*\.?\d{0,4}$/.test(value)) {
          setQty(value);
        }
        break;

      case "amount":
        if (/^\d*\.?\d{0,2}$/.test(value)) {
          setQty(value);
        }
        break;
    }

    // setQty(value);

    setSubmitClose(false);

    if (selectedPurchaseType === "amount" && value > 5000000) {
      setError(true);
      setErrorMsg("Investment amount cannot exceed Rs. 50,00,000.");
      setSubmitClose(true);

      return;
    }

    if (selectedPurchaseType === "amount") {
      calculateGstAmount(+value, "amount");
    }

    if (selectedPurchaseType === "quantity") {
      if (type === "silver" && value > 20000) {
        setError(true);
        setErrorMsg("Quantity cannot exceed 20000.");
        setSubmitClose(true);
        return;
      }

      if (type === "gold" && value > 1000) {
        setError(true);
        setErrorMsg("Quantity cannot exceed 1000.");
        setSubmitClose(true);
        return;
      }

      calculateGstAmount(+price * value, "quantity");
    }
  };

  const calculateGstAmount = (amount, purchaseType) => {
    const GST = 3;

    const gstTax = amount * (GST / 100);
    setGstAmount(gstTax);

    switch (purchaseType) {
      case "amount":
        setInvestAmount(amount - gstTax);
        setTotalAmount(amount);
        break;
      case "quantity":
        setInvestAmount(amount);
        setTotalAmount(amount + gstTax);
        break;
    }
  };

  useEffect(() => {
    const fetchCashFree = async () => {
      try {
        const data = await load({
          mode: process.env.REACT_APP_CASHFREE_ENVIRONMENT,
        });
        setCashFree(data); // Store the loaded data in state
        // Handle the cashFree data here
      } catch (error) {
        // Handle any errors here
        console.error("Error loading CashFree:", error);
      }
    };

    fetchCashFree();

    if (state) {
      setType(state.key);
    }
  }, []);

  useEffect(() => {
    const initialPrice = type === "gold" ? socketData?.gBuy : socketData?.sBuy;
    if (initialPrice) {
      localStorage.setItem("metalPrice", initialPrice);
      localStorage.setItem("block_id", socketData?.block_id);
      setPrice(initialPrice);
    }
  }, [type]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    // try {
    const order_amount = investAmount + gstAmount + paymentCharges;

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/buy`,
        {
          unique_id: receiver.uniqueId,
          blockId: localStorage.getItem("block_id"),
          lockPrice: localStorage.getItem("metalPrice"),
          metalType: type,
          purchaseType: selectedPurchaseType,
          quantity: selectedPurchaseType === "quantity" ? qty : null,
          amount: selectedPurchaseType === "amount" ? qty : null,
          orderAmount: order_amount.toFixed(2),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setIsFailure(true);
          setMsg(data.message);
          setIsLoading(false);
          return;
        }

        const { payment_session_id, order_id } = data.data; // Get payment_session_id from response

        localStorage.setItem("order_id", order_id);

        if (payment_session_id) {
          const options = {
            paymentSessionId: payment_session_id,
          };

          cashFree.checkout(options);
        }
      })
      .catch(({ response }) => {
        console.log("error from create account ->", response);
      });

    setIsLoading(false);
  };

  const handleCloseSnackbar = () => {
    setIsFailure(false);
  };

  const handleChangeMetalType = (e: any) => {
    const { value } = e.target;

    setType(value);
    setQty(0);
    setGstAmount(0);
    setInvestAmount(0);
    setTotalAmount(0);
    setPaymentCharges(0);
    setError(false);
    setErrorMsg("");
    setSubmitClose(false);
  };

  const handleMemberChange = (e: any) => {
    const { value } = e.target;

    if (value.length < 10) {
      setUniqueIdError(false);
      setUserUniqueErrorMsg("");
    }

    setReceiver({
      uniqueId: value,
      name: "",
      mobileNumber: "",
    });

    setSubmitClose(true);

    if (value.length === 10) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/search/member?uniqueId=${value}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => {
          const data = response.data;

          if (!data.succ) {
            setIsLoading(false);
            setUniqueIdError(true);
            setUserUniqueErrorMsg(data.message);
            return;
          }

          if (!data.data.kyc_status) {
            setIsLoading(false);
            setUniqueIdError(true);
            setUserUniqueErrorMsg(
              `${data.data.name} - ${data.data.mobileNumber} this user's Kyc is not verified. So You can't Purchase Gold / Silver for this user.`
            );
            return;
          }

          setIsLoading(false);

          setReceiver({
            uniqueId: data.data.unique_id,
            name: data.data.name,
            mobileNumber: data.data.mobileNumber,
          });

          setSubmitClose(false);

          setUniqueIdError(false);

          setUserUniqueErrorMsg(
            `${data.data.name} - ${data.data.mobileNumber}`
          );
        })
        .catch((error) => {
          setIsLoading(false);
          setIsFailure(true);
          // setMsg(data.message);
          return;
        });
    }
  };

  return (
    <>
      <TabsButtons investment={"gold"} page={"invest"} />

      <div style={{ margin: "2rem" }}>
        <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
          <CardContent>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <TextField
                label="Metal"
                name="Metal"
                value={type}
                onChange={(e) => handleChangeMetalType(e)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {metalType.map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                type="text"
                label="Price"
                name="Price"
                variant="outlined"
                fullWidth
                margin="normal"
                value={type !== "" ? parseFloat(price).toFixed(2) : 0}
                // autoFocus={true}
                // onChange={(e) => setPrice(e.target.value)}
                disabled={type !== "" ? true : false}
                helperText={
                  type !== "" ? (
                    <h5>Price Is Valid until {formatTime(timeRemaining)}</h5>
                  ) : (
                    ""
                  )
                }
              />

              <Grid container>
                <Grid xs={10}>
                  <TextField
                    label="UniqueId"
                    value={receiver.uniqueId}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={handleMemberChange}
                    error={uniqueIdError}
                    helperText={UserUniqueErrorMsg}
                    required
                  />
                </Grid>
                <Box
                  sx={{
                    margin: "15px 0 0 20px",
                    border: "1px solid rgb(179, 179, 179)",
                    borderRadius: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  height={56}
                  width={70}
                >
                  <Tooltip
                    title={
                      <Box sx={{ fontSize: "1rem", textAlign: "center" }}>
                        <div>
                          Kindly update the Unique ID in case you want to
                          purchase Gold / Silver for some other investor.
                        </div>
                      </Box>
                    }
                    arrow
                    placement="top" // Ensures tooltip appears above the icon
                  >
                    <InfoIcon fontSize="medium" sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </Box>
              </Grid>

              <TextField
                label="Purchase Type"
                name="Purchase Type"
                value={selectedPurchaseType}
                onChange={(e) => handleChangePurchaseType(e)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {purchaseType.map((ele: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={ele.value}>
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                type="number"
                id="outlined-basic"
                label={
                  selectedPurchaseType === "amount"
                    ? "Amount"
                    : "Quantity (In Grams)"
                }
                variant="outlined"
                fullWidth
                margin="normal"
                value={qty}
                // autoFocus
                onChange={handleChangeAmount}
                error={error}
                helperText={errorMsg}
                required
              />
              {/* <TextField
                label="Payment Mode"
                name="Payment Mode"
                onChange={(e) => calculatePlatformCharges(e)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {goldPurchasePaymentMode?.map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
              </TextField> */}
              <Typography sx={{ textAlign: "end" }}>
                Invest Amount :{" "}
                {investAmount ? investAmount.toFixed(2) : "0.00"}
              </Typography>
              <Typography sx={{ textAlign: "end" }}>
                Gst Tax (3%) : {gstAmount ? gstAmount.toFixed(2) : "0.00"}
              </Typography>
              <Typography sx={{ textAlign: "end" }}>
                Total Amount : {totalAmount ? totalAmount.toFixed(2) : "0.00"}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || submitClose}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </CardContent>
          <Box sx={{ padding: "10px" }}>
            <Divider variant="middle" sx={{ background: "#8a8686" }} />
            <p
              style={{
                padding: "10px 10px 0 0",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              * Please Refer{" "}
              <a
                href={`${process.env.REACT_APP_BACKEND_HOST}/Terms&Conditions.html`}
                style={{ color: "#0000FF" }}
                target="blank"
              >
                Terms & Conditions
              </a>
            </p>
            <p
              style={{
                padding: "4px 10px 0 0",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              * Please Refer{" "}
              <a
                href={`${process.env.REACT_APP_BACKEND_HOST}/FAQs.html`}
                style={{ color: "#0000FF" }}
                target="blank"
              >
                FAQs
              </a>
            </p>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0 10px 10px 0",
              }}
            >
              <img src="/PowerdByAugmonte.jpg" width={150} />
            </Box>
          </Box>
          <Snackbar
            open={isFailure}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            sx={{ marginBottom: 2 }}
          >
            <Alert severity="error">{Msg}</Alert>
          </Snackbar>
        </Card>
      </div>
    </>
  );
};

export default BuyGoldSilver;
