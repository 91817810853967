import {
  Box,
  Grid,
  Typography,
  CardContent,
  Card,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import PlaceIcon from "@mui/icons-material/Place";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import TransgenderIcon from "@mui/icons-material/Transgender";

const PreIpoProfileCard = ({
  user,
  setOpenIpoAccount,
  setIsOpenBank,
  setIsKycOpen,
}) => {
  return (
    <Grid container sx={{ marginTop: 2 }}>
      {/* {type === "profile" ? ( */}
      <Grid xs={12} md={5}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "text.secondary",
                }}
              >
                Profile
              </Typography>
              {user?.kyc_status !== "Kyc Verified" && (
                <IconButton
                  aria-label="editor"
                  color="primary"
                  onClick={() => setOpenIpoAccount(true)}
                >
                  <CreateTwoToneIcon sx={{ fontSize: 28 }} />
                </IconButton>
              )}
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 2,
                marginTop: 3,
              }}
            >
              <PersonRoundedIcon
                sx={{ color: "text.secondary", fontSize: 30 }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 2,
                marginTop: 3,
              }}
            >
              {(user?.customer.gender === "MALE" ||
                user?.customer.gender === "OTHER") && (
                <MaleIcon sx={{ color: "text.secondary", fontSize: 30 }} />
              )}
              {user?.customer.gender === "FEMALE" && (
                <FemaleIcon sx={{ color: "text.secondary", fontSize: 30 }} />
              )}
              {user?.customer.gender === "TRANSGENDER" && (
                <TransgenderIcon
                  sx={{ color: "text.secondary", fontSize: 30 }}
                />
              )}
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer.gender}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <PhoneRoundedIcon
                sx={{ color: "text.secondary", fontSize: 28 }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer.phone_code}
                {user?.customer.phone_number}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <EmailRoundedIcon
                sx={{ color: "text.secondary", fontSize: 28 }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer.email ?? "-"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <DateRangeRoundedIcon
                sx={{ color: "text.secondary", fontSize: 28 }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer.birth_date
                  ? new Date(user?.customer.birth_date).toDateString()
                  : ""}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <CreditCardRoundedIcon
                sx={{ color: "text.secondary", fontSize: 28 }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer_profile?.pan_number ?? "-"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <PlaceIcon sx={{ color: "text.secondary", fontSize: 28 }} />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {`${user?.customer_profile?.correspondance_address?.line_1} ${user?.customer_profile?.correspondance_address?.line_2} ${user?.customer_profile?.correspondance_address?.line_3}`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* ) : ( */}
      <Grid xs={12} md={5}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "text.secondary",
                }}
              >
                Bank & Demat
              </Typography>

              {user?.kyc_status !== "Kyc Verified" && (
                <IconButton
                  aria-label="editor"
                  color="primary"
                  onClick={() => setIsOpenBank(true)}
                >
                  <CreateTwoToneIcon sx={{ fontSize: 28 }} />
                </IconButton>
              )}
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 2,
                marginTop: 3,
              }}
            >
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                A/C no :
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer?.bank_account?.number}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                IFSC :
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer?.bank_account?.ifsc_code}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Demat A/C no :
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {user?.customer?.demat_account?.number}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: 5 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontWeight: 600 }} variant="h6">
                KYC
              </Typography>

              <Button
                variant="contained"
                color={
                  user?.kyc_status === "Kyc Verified"
                    ? "success"
                    : user?.kyc_status === "Kyc Rejected"
                    ? "warning"
                    : "primary"
                }
                onClick={() => {
                  if (user?.kyc_status !== "Kyc Verified") {
                    setIsKycOpen(true);
                  }
                }}
              >
                {user?.kyc_status}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* )} */}
    </Grid>
  );
};

export default PreIpoProfileCard;
