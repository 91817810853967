import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { SocketContext } from "../../../Store/SocketContext";
import {
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
  Snackbar,
  Alert,
  Link,
  Grid,
} from "@mui/material";
import { metalType, purchaseType } from "../../../Data/data";
import { load } from "@cashfreepayments/cashfree-js";
import TabsButtons from "../TabsButtons";
import { useLocation } from "react-router-dom";
import ViewPriceList from "../Explore/ViewPriceList";

const IpoInvestMent = ({ user, accessToken }) => {
  const { state }: any = useLocation();

  const [category, setCategory] = useState<string>("live");
  const [unlistedFunds, setUnlistedFunds] = useState<any>([]);
  const [selectedFund, setSelectedFund] = useState<any>();
  const [submitClose, setSubmitClose] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const [isFailure, setIsFailure] = useState(false);
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPriceSlabOpen, setIsPriceSlabOpen] = useState<boolean>(false);
  const [units, setUnits] = useState<string>("0");
  const [price, setPrice] = useState<string>("0");
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [fundError, setFundError] = useState<boolean>(false);
  const [fundErrorMsg, setFundErrorMsg] = useState<string>("");

  const categoryValues = [
    {
      label: "Live",
      value: "live",
    },
    {
      label: "Upcoming",
      value: "upcoming",
    },
    {
      label: "Historical",
      value: "historical",
    },
  ];

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST + `v1/preIpo/products?category=live`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        const funds = data.data.collection.map((fund: any) => {
          return {
            value: fund.isin,
            label: fund.company.display_name,
            price: fund.price.price,
            trade_date: fund.price.trade_date,
            min_units: fund.min_units,
          };
        });

        if (state?.isin) {
          const fund = funds.find((fund: any) => fund.value === state?.isin);

          if (fund === undefined && !fund) {
            setUnlistedFunds([
              ...funds,
              {
                value: state.isin,
                label: state.name,
                min_units: 0,
                price: 0,
                trade_date: "",
              },
            ]);

            setFundError(true);
            setFundErrorMsg("This Fund Is not Available any more. ");
            setSubmitClose(true);

            return;
          } else {
            setUnlistedFunds(funds);
          }

          setSelectedFund(fund);
          setUnits(fund.min_units);
          setPrice(fund.price);
        } else {
          setUnlistedFunds(funds);
        }
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  }, []);

  const handleProductSelect = (e: any) => {
    const { value } = e.target;

    setFundError(false);
    setFundErrorMsg("");
    setError(false);
    setErrorMsg("");
    setSubmitClose(false);

    const fund = unlistedFunds.find((fund: any) => fund.value === value);

    if (!fund) {
      setFundError(true);
      setFundErrorMsg("This Fund Is not Available any more. ");
      setSubmitClose(true);

      return;
    }

    setSelectedFund(fund);
    setUnits(fund.min_units);
    setPrice(fund.price);
  };

  const handleUnitChange = (e: any) => {
    const { value } = e.target;

    setError(false);
    setErrorMsg("");
    setSubmitClose(false);

    setUnits(value);

    if (value < selectedFund.min_units) {
      setError(true);
      setErrorMsg(
        `Investment units should be equal to or greater than the minimum units ${selectedFund.min_units}.`
      );
      setSubmitClose(true);
      return;
    }

    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          `v1/preIpo/product/price?isin=${selectedFund.value}&date=${selectedFund.trade_date}&units=${value}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        setPrice(data.data.unit_price);
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const body = {
      isin: selectedFund.value,
      units: parseInt(units),
      unit_price: parseFloat(price),
      trade_date: selectedFund.trade_date,
    };

    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/place/order`,
        body,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setIsLoading(false);
          setMsg(data.message);
          return;
        }

        const link = data.data.data.link;

        window.open(link, "_blank"); // Opens in a new tab

        setSuccess(true);
        setMsg(data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };

  return (
    <>
      <TabsButtons investment={"ipo"} page={"invest"} />

      <div style={{ margin: "2rem" }}>
        <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
          <CardContent>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              {/* <TextField
                label="Category"
                name="Category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setSelectedFund(null);
                  setUnits("0");
                  setPrice("0");
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {categoryValues.map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
              </TextField> */}

              <TextField
                label="Company Name"
                name="Company Name"
                value={
                  selectedFund !== undefined ? selectedFund?.value : state?.isin
                }
                onChange={handleProductSelect}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
                error={fundError}
                helperText={fundErrorMsg}
              >
                {unlistedFunds.map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
              </TextField>

              {selectedFund && (
                <Box display={"flex"} justifyContent={"center"}>
                  <Link
                    variant="body2"
                    sx={{
                      mt: 1,
                      color: "red",
                      textAlign: "center",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={(e: any) => {
                      setIsPriceSlabOpen(true);
                    }}
                  >
                    Grab Discount
                  </Link>
                </Box>
              )}

              <TextField
                label="Price"
                name="Price"
                variant="outlined"
                type="number"
                fullWidth
                margin="normal"
                value={price}
              />

              <TextField
                type="number"
                id="outlined-basic"
                label="Units"
                variant="outlined"
                fullWidth
                margin="normal"
                value={units}
                onChange={handleUnitChange}
                error={error}
                helperText={errorMsg}
              />

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Total Investment Amount
                  </Typography>
                  <Typography variant="h6">
                    ₹{" "}
                    {parseFloat(price) * parseFloat(units === "" ? "0" : units)}
                  </Typography>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || submitClose || fundError}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </CardContent>
          <Snackbar
            open={isSuccess}
            autoHideDuration={4000}
            onClose={closeHandleSnackbar}
          >
            <Alert severity="success">{msg}</Alert>
          </Snackbar>
          <Snackbar
            open={isFailure}
            autoHideDuration={4000}
            onClose={closeHandleSnackbar}
          >
            <Alert severity="error">{msg}</Alert>
          </Snackbar>
        </Card>
      </div>
      {isPriceSlabOpen && (
        <ViewPriceList
          isPriceSlabOpen={isPriceSlabOpen}
          setIsPriceSlabOpen={setIsPriceSlabOpen}
          price={selectedFund.price}
          token={accessToken}
          date={selectedFund.trade_date}
          isin={selectedFund.value}
        />
      )}
    </>
  );
};

export default IpoInvestMent;
