import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { FormatNumber } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import TabsButtons from "../../Admin/TabsButtons";
import InfoIcon from "@mui/icons-material/Info";
import SuperAdminIpoAccountModal from "./SuperAdminPreIpoAccountModel";
import SuperAdminPreIpoKycModel from "./SuperAdminPreIpoKycModel";
import SuperAdminPreIpoBankModel from "./SuperAdminPreIpoBankModel";
import PreIpoProfileCard from "../../Admin/Ipo/PreIpoProfileCard";

export default function CreatePreIpoAccount({ accessToken }) {
  const { state }: any = useLocation();
  const { id } = useParams();

  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [isKycOpen, setIsKycOpen] = useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [storeState, dispatch] = useAppContext();
  const [loading, setLoading] = useState(false);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [preIpoProfile, setPreIpoProfile] = useState<any>(null);
  const [kyc_status, setKycStatus] = useState<string>("Pending");
  const [openIpoAccount, setOpenIpoAccount] = useState<boolean>(false);

  const fetchAccountData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/preIpo/profile?id=${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (data.succ) {
          const userData = data.data;

          setPreIpoProfile(userData);

          setKycStatus(userData.kyc_status);

          setIsUpdated(false);
        } else {
        }
      })
      .catch((error) => {
        console.log("Error from get pre ipo user details ->", error);
      });
    setLoading(false);
  };

  // console.log("user ->", user);

  useEffect(() => {
    if (isUpdated) fetchAccountData();
  }, [isUpdated]);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment={"ipo"} page={"createAccount"} />

      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Pre IPO Shares
          </Typography>
          <Grid container spacing={2}>
            {preIpoProfile ? (
              <PreIpoProfileCard
                user={preIpoProfile}
                setOpenIpoAccount={setOpenIpoAccount}
                setIsOpenBank={setIsOpenBank}
                setIsKycOpen={setIsKycOpen}
              />
            ) : (
              <Grid xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="h6">
                        Create Account
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setOpenIpoAccount(true)}
                      >
                        Pending
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {!preIpoProfile && (
              <Grid xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="h6">
                        Bank And Demat Details
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setIsOpenBank(true)}
                      >
                        Pending
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          {!preIpoProfile && (
            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <Card sx={{ marginTop: 5 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: 600 }} variant="h6">
                        KYC
                      </Typography>

                      <Button
                        variant="contained"
                        color={
                          kyc_status === "Kyc Verified"
                            ? "success"
                            : kyc_status === "Kyc Rejected"
                            ? "warning"
                            : "primary"
                        }
                        onClick={() => {
                          if (kyc_status !== "Kyc Verified") {
                            setIsKycOpen(true);
                          }
                        }}
                      >
                        {kyc_status ?? "Pending"}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
      {openIpoAccount && (
        <SuperAdminIpoAccountModal
          openAccount={openIpoAccount}
          setOpenIpoAccount={setOpenIpoAccount}
          accessToken={accessToken}
          // userData={userData?.user ? userData?.user : user}
          userData={preIpoProfile?.customer}
          userAddress={preIpoProfile?.customer_profile}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isKycOpen && (
        <SuperAdminPreIpoKycModel
          isKycOpen={isKycOpen}
          setIsKycOpen={setIsKycOpen}
          userData={preIpoProfile?.user}
          panData={
            preIpoProfile?.user?.pan?.panNumber
            // userData?.user?.pan
            //   ? userData?.user?.pan?.panNumber
            //   : user.panNumber
          }
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isOpenBank && (
        <SuperAdminPreIpoBankModel
          isOpenBank={isOpenBank}
          setIsOpenBank={setIsOpenBank}
          userData={preIpoProfile?.user}
          bank={{}}
          accessToken={accessToken}
          setSuccess={setSuccess}
          setIsUpdated={setIsUpdated}
          setError={setError}
          setMsg={setMsg}
        />
      )}

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
}
