import { Grid, Box, Tab, Stack } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import "rsuite/dist/rsuite.min.css";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import TabsButtons from "../../Admin/TabsButtons";
import OtherInvestmentDateWiseTransactions from "./DateWiseTransactions";

const OtherInvestmentTransactions = ({ accessToken }: any) => {
  const { state } = useLocation();
  const [value, setValue] = React.useState<any>(state == "2" ? state : "1");

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2} xs>
      <Box>
        <TabContext value={value}>
          {/* <InvestmentTabs /> */}
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              mb: "15px",
              mt: "30px",
              mx: "20px",
              gap: "20px",
            }}
          >
            <TabsButtons investment={"other"} page={"transaction"} />
          </Stack>

          <Box
            sx={{
              borderBottom: 2,
              margin: "15px 0 0 15px",
              borderColor: "divider",
              bgcolor: "#318ad6",
              color: "white",
              width: { xs: "100vw", md: "81.75vw" },
              overflowX: "scroll",
            }}
          >
            <TabList
              onChange={(event, newValue) => handleChange(newValue)}
              TabIndicatorProps={{
                style: { backgroundColor: "white", color: "white" },
              }}
              style={{ width: "35rem" }}
            >
              <Tab
                style={{ color: "white", fontWeight: 650 }}
                label="Transactions"
                value="1"
              />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: 0 }}>
            <OtherInvestmentDateWiseTransactions accessToken={accessToken} />
          </TabPanel>
        </TabContext>
      </Box>
    </Grid>
  );
};
export default OtherInvestmentTransactions;
