import { useTheme } from "@emotion/react";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { Fragment, useState } from "react";
import { assetType } from "../../../Data/data";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const RedeemOtherInvestment = ({
  accessToken,
  isOpenRedeem,
  setIsOpenRedeem,
  setSelectedInvestment,
  user_id,
  setIsUpdate,
  setSuccess,
  setError,
  setMsg,
  selectedInvestment,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>(
    selectedInvestment?.assetType ?? ""
  );
  const [issuer, setIssuer] = useState<string>(
    selectedInvestment?.issuer ?? ""
  );
  const [amount, setAmount] = useState<string>("");
  const [date, setDate] = useState<string>("");

  const changeHandler = (value: any) => {
    setSelectedType(value);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const body = {
      id: selectedInvestment._id,
      date,
      user_id: user_id,
      assetType: selectedType,
      issuer: issuer,
      redeemAmount: Number(amount.replace(/,/g, "")),
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/otherInvestments/redeem`,
        body,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          return;
        }

        setIsUpdate(true);
        setSuccess(true);
        setMsg(data.message);
        setIsOpenRedeem(false);
        setSelectedInvestment(null);
      })
      .catch((error) => {
        console.log("error from add other Investment ->", error);
      });

    setIsLoading(false);
  };

  return (
    <Fragment>
      <Dialog
        // fullScreen={"md"}
        open={isOpenRedeem}
        onClose={() => setIsOpenRedeem(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Redeem Investments
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                onChange={(date: any) => setDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={false} // Ensure error prop is explicitly set to false
                    helperText="" // Remove any helper text
                    margin="normal"
                    // required
                    fullWidth
                  />
                )}
                value={date}
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <TextField
              value={selectedType}
              required
              onChange={(e) => {
                changeHandler(e.target.value);
              }}
              select
              type="text"
              id="outlined-basic"
              label="Asset Type"
              variant="outlined"
              defaultValue="once"
              margin="normal"
              fullWidth
              // helperText="Please select your currency"
              //   style={{ width: "100%" }}
            >
              {assetType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              id="outlined-basic"
              label="Enter issuer"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={issuer}
              onChange={(e) => setIssuer(e.target.value)}
            />

            <NumericFormat
              type="text"
              label="Amount"
              name="view_amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              thousandsGroupStyle="lakh"
              thousandSeparator=","
              customInput={TextField}
              decimalScale={2}
            />

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsOpenRedeem(false)}
              >
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default RedeemOtherInvestment;
