import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useAppContext } from "../../../Store/AppContext";

const IpoBankModal = ({
  isOpenBank,
  setIsOpenBank,
  userData,
  accessToken,
  setSuccess,
  setIsUpdated,
  setError,
  setMsg,
}: any) => {
  const [storeState, dispatch] = useAppContext();

  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);

  const [account, setAccount] = useState<string>(
    userData?.bank_account?.number ?? userBank[0].ACNUM
  );
  const [dematNumber, setDematNumber] = useState<string>(
    userData?.demat_account?.number ?? ""
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ifsc, setIfsc] = useState<string>(
    userData?.bank_account?.ifsc_code ?? userBank[0].IFSC
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setIsOpenBank(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    let bankObj = {
      account_number: account,
      demat_number: dematNumber,
      ifsc_code: ifsc,
    };

    setIsLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/bank`, bankObj, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setMsg(data.message);
        }
        setSuccess(true);
        setMsg(data.message);
        handleClose();
        setIsUpdated(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("bank Details Error ->", error);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={isOpenBank}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add Bank Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Account Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              required
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Ifsc Code"
              variant="outlined"
              fullWidth
              margin="normal"
              value={ifsc}
              onChange={(e) => setIfsc(e.target.value)}
              required
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Demat Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={dematNumber}
              onChange={(e) => setDematNumber(e.target.value)}
              required
            />

            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default IpoBankModal;
