import { TabContext } from "@material-ui/lab";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../Store/AppContext";
import { useEffect, useState } from "react";

const TabsButtons = ({ investment, page }) => {
  const navigate = useNavigate();
  const [storeState, dispatch] = useAppContext();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    let getUser: any = localStorage.getItem("user");

    getUser = JSON.parse(getUser);
    setUser(getUser);
  }, []);

  let { id } = useParams();

  return (
    <>
      {page === "invest" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={""}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor:
                      investment === "mutual_fund" ? "#1976D2" : "#ffffff",
                    color: investment === "mutual_fund" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate("/dashboardAdmin/nse/multi/order/:folio")
                  } // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "gold" ? "#1976D2" : "#ffffff",
                    color: investment === "gold" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/goldInvesting")} // Replace with your actual route
                >
                  Gold
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/apply-ipo")} // Replace with your actual route
                >
                  Pre IPO Shares
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}

      {page === "explore" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor:
                      investment === "mutual_fund" ? "#1976D2" : "#ffffff",
                    color: investment === "mutual_fund" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate("/dashboardAdmin/mutual-fund-explore")
                  } // Replace with your actual route
                >
                  Mutual Funds
                </Button>
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/pre-ipo-explore")} // Replace with your actual route
                >
                  Pre IPO Shares
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}

      {page === "home" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor:
                      investment === "mutual_fund" ? "#1976D2" : "#ffffff",
                    color: investment === "mutual_fund" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/investing")} // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "gold" ? "#1976D2" : "#ffffff",
                    color: investment === "gold" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/goldInvestment")} // Replace with your actual route
                >
                  Gold
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/pre-ipo")} // Replace with your actual route
                >
                  Pre IPO Shares
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "other" ? "#1976D2" : "#ffffff",
                    color: investment === "other" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/other")} // Replace with your actual route
                >
                  Other Investments
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}

      {page === "transaction" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor:
                      investment === "mutual_fund" ? "#1976D2" : "#ffffff",
                    color: investment === "mutual_fund" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardAdmin/investment/nse/details/${storeState?.ACTIVEINVETOR?.folio?.Folio}`
                    )
                  } // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "gold" ? "#1976D2" : "#ffffff",
                    color: investment === "gold" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/gold-transactions")} // Replace with your actual route
                >
                  Gold
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/preIpoTransactions")} // Replace with your actual route
                >
                  Pre IPO Shares
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "other" ? "#1976D2" : "#ffffff",
                    color: investment === "other" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/other/transactions")} // Replace with your actual route
                >
                  Other Investments
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}

      {page === "view" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor:
                      investment === "mutual_fund" ? "#1976D2" : "#ffffff",
                    color: investment === "mutual_fund" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(`/dashboardSuper/view/${user?.folio?.Folio}`, {
                      state: user,
                    })
                  } // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "gold" ? "#1976D2" : "#ffffff",
                    color: investment === "gold" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/view/gold/${
                        user?.user_id?._id ?? user?.user_id?.id ?? id
                      }`
                    )
                  } // Replace with your actual route
                >
                  Gold
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/view/preIpo/${
                        user?.user_id?._id ?? user?.user_id?.id ?? id
                      }`
                    )
                  } // Replace with your actual route
                >
                  Pre IPO Shares
                </Button>
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "other" ? "#1976D2" : "#ffffff",
                    color: investment === "other" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/view/other/${
                        user?.user_id?._id ?? user?.user_id?.id ?? id
                      }`
                    )
                  } // Replace with your actual route
                >
                  Other Investments
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}

      {page === "createAccount" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "gold" ? "#1976D2" : "#ffffff",
                    color: investment === "gold" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(`/dashboardSuper/gold/createAccount/${id}`, {
                      state: user,
                    })
                  } // Replace with your actual route
                >
                  Gold
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(`/dashboardSuper/preIpo/createAccount/${id}`)
                  }
                >
                  Pre Ipo
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "other" ? "#1976D2" : "#ffffff",
                    color: investment === "other" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(`/dashboardSuper/other/createAccount/${id}`)
                  }
                >
                  Other Investment
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}

      {page === "superAdminTransaction" && (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor:
                      investment === "mutual_fund" ? "#1976D2" : "#ffffff",
                    color: investment === "mutual_fund" ? "#ffffff" : "#1976D2",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/transaction/${user?.folio?.Folio}`,
                      {
                        state: user,
                      }
                    )
                  } // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "gold" ? "#1976D2" : "#ffffff",
                    color: investment === "gold" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/gold/transaction/${
                        user.user_id._id ?? user.user_id.id
                      }`
                    )
                  } // Replace with your actual route
                >
                  Gold
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "ipo" ? "#1976D2" : "#ffffff",
                    color: investment === "ipo" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/preIpo/transaction/${
                        user.user_id._id ?? user.user_id.id
                      }`
                    )
                  } // Replace with your actual route
                >
                  Pre Ipo
                </Button>

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #1976D2",
                    bgcolor: investment === "other" ? "#1976D2" : "#ffffff",
                    color: investment === "other" ? "#ffffff" : "#1976D2",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#1976D2",
                      border: "1px solid #fffff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboardSuper/other/transaction/${
                        user.user_id._id ?? user.user_id.id
                      }`
                    )
                  } // Replace with your actual route
                >
                  Other Investments
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default TabsButtons;
