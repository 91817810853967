import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Link,
  IconButton,
  TableCell,
  TableContainer,
  Alert,
  Snackbar,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { FormatNumber } from "../../../utils/formatNumber";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ViewPriceList from "./ViewPriceList";
import { Pie } from "react-chartjs-2";
import { LineChart } from "@mui/x-charts/LineChart";
import moment from "moment";
import { tableCellClasses } from "@mui/material/TableCell";
import { fontSize } from "@mui/joy/styles/styleFunctionSx";

const PreIpoDetailView = ({ accessToken }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(null);
  const { isin } = useParams();
  const [units, setUnits] = useState<any>(0);
  const [holdingsData, setHoldingsData] = useState<any>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isPriceSlabOpen, setIsPriceSlabOpen] = useState<boolean>(false);
  const [productPrice, setProductPrice] = useState<number>(0);
  const [annualReports, setAnnualReports] = useState<any>([]);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<string>("1Y");
  const [productPriceHistory, setProductPriceHistory] = useState<any>([]);
  const [priceDiffObj, setPriceDiffObj] = useState<{
    priceDiff: string;
    diffPer: number;
  }>({ priceDiff: "0", diffPer: 0 });
  const [msg, setMsg] = useState<string>("");
  const [view, setView] = useState<string>("profitLoss");
  const [keyFinancial, setKeyFinancial] = useState<any>([]);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);

  useEffect(() => {
    getProductDetail();
  }, []);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const timeRange = ["1M", "3M", "1Y", "2Y", "3Y", "All"];

  const transformedData = (data: any) => {
    setProductPriceHistory(
      data.map((item: any) => {
        const date = new Date(item.trade_date);
        return {
          date: date, // The date with no time portion
          price: item.price,
        };
      })
    );
  };

  const holdings = {
    datasets: [
      {
        label: "Shareholding %",
        data: holdingsData
          ? holdingsData.map((item) =>
              typeof item.percentage === "string"
                ? parseFloat(item.percentage.replace(/%/g, ""))
                : item.percentage
            )
          : [],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#C9CBCF",
          "#E7E9ED",
          "#89A4C5",
          "#2E5289",
          "#4B618C",
          "#B0C7DC",
        ],
        borderWidth: 1,
      },
    ],
  };

  const optionsPie = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      display: false,
      legend: {
        position: "right" as const,
        labels: {
          font: {
            family: "Montserrat",
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    onHover: (event: any, elements: any) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        setHoveredIndex(index);
      } else {
        setHoveredIndex(null);
      }
    },
  };

  const handleUnitChange = (e: any) => {
    const { value } = e.target;
    setUnits(value);
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          `v1/preIpo/product/price?isin=${isin}&date=${product?.price.trade_date}&units=${value}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        console.log("Price list ->", data.data);
        setProductPrice(data.data.unit_price);
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };

  const handleSubmit = () => {
    const body = {
      isin: product.isin,
      units: parseInt(units),
      unit_price: productPrice,
      trade_date: product?.price.trade_date,
    };

    setIsFormSubmit(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/preIpo/place/order`,
        body,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setIsFormSubmit(false);
          setMsg(data.message);
          return;
        }

        const link = data.data.data.link;

        window.open(link, "_blank"); // Opens in a new tab

        setSuccess(true);
        setMsg(data.message);
        setIsFormSubmit(false);
      })
      .catch((err) => {
        console.log("err ->", err);
        setIsFormSubmit(false);
      });
  };

  const handleSelectTimeRange = (range: any, priceHistory: any) => {
    setSelectedRange(range);

    let priceRangeHistory: any = [];

    switch (range) {
      case "1M":
        priceRangeHistory = priceHistory.slice(priceHistory.length - 30);
        break;
      case "3M":
        priceRangeHistory = priceHistory.slice(priceHistory.length - 90);
        break;
      case "1Y":
        priceRangeHistory = priceHistory.slice(priceHistory.length - 365);
        break;
      case "2Y":
        priceRangeHistory = priceHistory.slice(priceHistory.length - 730);
        break;
      case "3Y":
        priceRangeHistory = priceHistory.slice(priceHistory.length - 1095);
        break;
      default:
        priceRangeHistory = priceHistory;
        break;
    }

    const todayPrice = priceRangeHistory[priceRangeHistory.length - 1].price;
    const previousPrice = priceRangeHistory[0].price;
    const priceDiff = todayPrice - previousPrice;
    const diffPer = (priceDiff / previousPrice) * 100;

    transformedData(priceRangeHistory);

    setPriceDiffObj({
      priceDiff:
        priceDiff > 0 ? `+${priceDiff.toFixed(2)}` : `${priceDiff.toFixed(2)}`,
      diffPer,
    });
  };

  const getProductDetail = () => {
    setIsLoading(true);

    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          `v1/preIpo/product/detail?isin=${isin}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        setProduct(data.data);

        setUnits(data?.data?.min_units);
        setHoldingsData(data?.data?.company?.shareholding_pattern);

        setAnnualReports(
          Object.keys(data.data.annual_reports).length === 0
            ? []
            : data.data.annual_reports.data
        );

        setKeyFinancial(
          Object.keys(data.data.profit_and_loss).length === 0
            ? []
            : data.data.profit_and_loss
        );

        setProductPrice(data.data.price.price);

        handleSelectTimeRange(selectedRange, data.data.price_history);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err ->", err);
      });
  };

  const handelChangeKeyFinancial = (tab) => {
    setView(tab);

    if (tab === "profitLoss") {
      setKeyFinancial(product.profit_and_loss);
    } else {
      setKeyFinancial(product.company_financials);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ padding: { xs: 1, md: 3, lg: 5 } }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                display: "flex",
                flexFlow: "column",
                gap: 5,
                order: { xs: 2, lg: 1 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  paddingLeft: "10px",
                }}
              >
                <img
                  src={`${product?.company.logo_url}`}
                  width={59}
                  height={59}
                />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", paddingLeft: "30px" }}
                >
                  {product?.company.display_name}
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                {productPriceHistory.length > 0 && (
                  <Card
                    sx={{
                      maxWidth: 1000,
                      padding: 2,
                      boxShadow: 3,
                      borderRadius: 3,
                    }}
                  >
                    <CardContent>
                      {/* Price Header */}
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        ₹{productPrice}{" "}
                        <Typography
                          component="span"
                          sx={{
                            color: priceDiffObj.diffPer < 0 ? "red" : "green",
                            fontSize: "1rem",
                          }}
                        >
                          {priceDiffObj.priceDiff} (
                          {priceDiffObj.diffPer.toFixed(2)}%) {selectedRange}
                        </Typography>
                      </Typography>

                      {/* Material UI LineChart */}
                      <LineChart
                        dataset={productPriceHistory}
                        xAxis={[
                          {
                            dataKey: "date",
                            valueFormatter: (date) =>
                              moment(date).format("DD/MM/YYYY"),
                          },
                        ]}
                        yAxis={[
                          {
                            dataKey: "price",
                            label: "Price (₹)", // Show only "Price" on the y-axis
                            valueFormatter: () => "", // Hides y-axis labels
                          },
                        ]}
                        series={[
                          {
                            id: "PriceTrend",
                            dataKey: "price",
                            color: "blue",
                            area: false,
                            showMark: false,
                          },
                        ]}
                        width={800}
                        height={400}
                        margin={{ left: 50, right: 30, top: 30, bottom: 30 }}
                      />

                      {/* Clickable Time Range Filters */}
                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                        mt={1}
                      >
                        {timeRange.map((range) => (
                          <Button
                            key={range}
                            size="small"
                            // variant="outlined"
                            variant={
                              selectedRange === range ? "contained" : "outlined"
                            }
                            sx={{
                              minWidth: "40px",
                              padding: "2px 8px",
                              fontSize: "0.8rem",
                              textTransform: "none",
                            }}
                            onClick={() =>
                              handleSelectTimeRange(
                                range,
                                product.price_history
                              )
                            }
                          >
                            {range}
                          </Button>
                        ))}
                      </Stack>
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Paper
                elevation={1}
                sx={{ p: { xs: 1, sm: 4 }, borderRadius: 3, boxShadow: 3 }}
              >
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: "700" }}>
                    Fundamentals
                  </Typography>
                  <Divider sx={{ mb: 2, color: "blue" }} />
                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>Current Price:</span>
                        <span>
                          {product ? FormatNumber(product?.price?.price) : "-"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>Market Cap:</span>
                        <span>{product?.distribution?.market_cap}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>ISIN:</span>
                        <span>{product?.isin}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>Face Value</span>
                        <span>₹{product?.distribution?.face_value}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>P/E Ratio:</span>
                        <span>
                          {product?.distribution?.price_to_earnings_ratio}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>EPS:</span>
                        <span>{product?.distribution?.earnings_per_share}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>P/B Ratio:</span>
                        <span>
                          {product?.distribution?.price_to_book_ratio}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>Book Value:</span>
                        <span>{product?.book_value}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>Debt to Equity Ratio:</span>
                        <span>{product?.debt_to_equity_ratio}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              {/* Product Key Financial */}

              {keyFinancial.length > 0 && (
                <Paper
                  elevation={1}
                  sx={{ p: { xs: 1, sm: 4 }, borderRadius: 3, boxShadow: 3 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "700" }}>
                      Key Financials
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={view}
                      exclusive
                      onChange={(event, newView) =>
                        handelChangeKeyFinancial(newView)
                      }
                      sx={{ marginBottom: 2 }}
                    >
                      <ToggleButton value="profitLoss">
                        Profit & Loss
                      </ToggleButton>
                      <ToggleButton value="balanceSheet">
                        Balance Sheet
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <Divider sx={{ mb: 2, color: "blue" }} />
                  <TableContainer
                    sx={{ borderRadius: "12px", overflow: "hidden" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>(In Rs. Crore)</StyledTableCell>
                          {keyFinancial?.fiscal_years.map((row: any) => (
                            <StyledTableCell>{row}</StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {keyFinancial?.data.map((row: any, index: number) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{row.title}</StyledTableCell>
                            {row.values.map((cell: any) => (
                              <StyledTableCell>{cell}</StyledTableCell>
                            ))}
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}

              {/* Product Share Holding Part */}

              {holdingsData.length > 0 && (
                <Paper
                  elevation={1}
                  sx={{ p: { xs: 1, sm: 4 }, borderRadius: 3, boxShadow: 3 }}
                >
                  <Box>
                    <Typography variant="h5" sx={{ fontWeight: "700" }}>
                      Shareholding
                    </Typography>
                    <Divider sx={{ mb: 2, color: "blue" }} />
                    <Grid container xs={12}>
                      <Grid xs={7} sm={6} md={4}>
                        <Pie options={optionsPie} data={holdings} />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        md={8}
                        sx={{ marginTop: { xs: 2, sm: 0 } }}
                      >
                        <Table aria-label="simple table">
                          <TableHead sx={{ background: "#48a2f755" }}>
                            <TableRow sx={{ fontWeight: "bold" }}>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Holding</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {holdingsData.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    hoveredIndex === index
                                      ? "#9fd1ff53"
                                      : "transparent",
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ py: 0 }}
                                >
                                  {row.shareholder}
                                </TableCell>
                                <TableCell align="right" sx={{ py: 1 }}>
                                  {typeof row.percentage === "string"
                                    ? parseFloat(
                                        row.percentage.replace(/%/g, "")
                                      )
                                    : row.percentage}
                                  %
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              )}

              {/* Product Strengths and Weaknesses */}

              {(product?.strengths?.length > 0 ||
                product?.weaknesses.length > 0) && (
                <Paper
                  elevation={1}
                  sx={{ p: { xs: 1, sm: 4 }, borderRadius: 3, boxShadow: 3 }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "700" }}>
                    Strengths & Weaknesses
                  </Typography>
                  <Divider sx={{ mb: 2, color: "blue" }} />

                  {product?.strengths?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          color: "green",
                          border: "1px solid green",
                          borderRadius: "6px",
                          padding: "5px",
                          display: "inline-block",
                          marginY: "10px",
                        }}
                      >
                        Strengths
                      </Typography>
                      <List
                        sx={{
                          bgcolor: "background.paper",
                        }}
                      >
                        {product?.strengths.map((value) => (
                          <ListItem key={value} disableGutters>
                            <ListItemIcon
                              sx={{
                                color: "inherit",
                                alignSelf: "self-start",
                                paddingTop: "10px",
                                width: "25px",
                              }}
                            >
                              <FiberManualRecordIcon
                                sx={{ color: "#cecece", fontSize: "16px" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={value} />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}

                  {product?.weaknesses?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          color: "red",
                          border: "1px solid red",
                          borderRadius: "6px",
                          padding: "5px",
                          display: "inline-block",
                          marginY: "10px",
                        }}
                      >
                        Weaknesses
                      </Typography>
                      <List
                        sx={{
                          bgcolor: "background.paper",
                        }}
                      >
                        {product?.weaknesses.map((value) => (
                          <ListItem key={value} disableGutters>
                            <ListItemIcon
                              sx={{
                                color: "inherit",
                                alignSelf: "self-start",
                                paddingTop: "10px",
                              }}
                            >
                              <FiberManualRecordIcon
                                sx={{ color: "#cecece", fontSize: "16px" }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={value} />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </Paper>
              )}

              {(product?.company?.description ||
                product?.company?.overview) && (
                <Paper
                  elevation={1}
                  sx={{ p: { xs: 1, sm: 4 }, borderRadius: 3, boxShadow: 3 }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "700" }}>
                    About Co.
                  </Typography>
                  <Divider sx={{ mb: 2, color: "blue" }} />
                  <List
                    sx={{
                      bgcolor: "background.paper",
                    }}
                  >
                    {product?.company?.description && (
                      <ListItem disableGutters>
                        <ListItemText primary={product.company.description} />
                      </ListItem>
                    )}
                    {product?.company?.overview && (
                      <ListItem disableGutters>
                        <ListItemText primary={product.company.overview} />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              )}

              {(product?.board_of_directors.length > 0 ||
                product?.senior_management.length > 0 ||
                annualReports.length > 0) && (
                <Paper
                  elevation={1}
                  sx={{ p: { xs: 1, sm: 4 }, borderRadius: 3, boxShadow: 3 }}
                >
                  <Box>
                    {/* Header */}

                    <Typography variant="h5" sx={{ fontWeight: "700" }}>
                      Board of Directors & Management
                    </Typography>
                    <Divider sx={{ mb: 2, color: "blue" }} />

                    {/* Board of Directors */}

                    {product?.board_of_directors.length > 0 && (
                      <Box sx={{ mb: 4 }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            paddingBottom: "10px",
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          Board of Directors
                        </Typography>
                        <Grid container spacing={2}>
                          {product?.board_of_directors.map(
                            (director, index) => (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: "500" }}
                                >
                                  {director.full_name}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  sx={{ fontSize: "13px" }}
                                >
                                  {director.designation}
                                </Typography>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Box>
                    )}

                    {/* Senior Management */}

                    {product?.senior_management.length > 0 && (
                      <Box sx={{ mb: 4 }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            paddingBottom: "10px",
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          Senior Management
                        </Typography>
                        <Grid container spacing={2}>
                          {product?.senior_management.map((manager, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                {manager.full_name}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                sx={{ fontSize: "13px" }}
                              >
                                {manager.designation}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}

                    {/* Annual Reports */}
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          paddingBottom: "10px",
                          fontWeight: "600",
                        }}
                        gutterBottom
                      >
                        Annual Reports
                      </Typography>
                      <Grid container spacing={2}>
                        {annualReports.length > 0 && (
                          // <Card>
                          //   <CardContent>
                          <List>
                            {annualReports?.map((report, index) => (
                              <ListItem
                                secondaryAction={
                                  <IconButton edge="end">
                                    <Link
                                      href={report.report}
                                      target="_blank"
                                      rel="noopener"
                                      underline="hover"
                                    >
                                      <CloudDownloadIcon />
                                    </Link>
                                  </IconButton>
                                }
                              >
                                <ListItemText primary={report.title} />
                              </ListItem>
                            ))}
                          </List>
                          //   </CardContent>
                          // </Card>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} lg={3} sx={{ order: { xs: 1, lg: 2 } }}>
              <Card
                sx={{
                  // maxWidth: 400,
                  // margin: "auto",
                  borderRadius: 3,
                  boxShadow: 3,
                  position: "sticky",
                  top: "80px",
                }}
              >
                <CardContent>
                  <div style={{ padding: "0 20px" }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {product?.company.display_name}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      ₹{productPrice}{" "}
                      <Typography
                        component="span"
                        sx={{
                          color: priceDiffObj.diffPer < 0 ? "red" : "green",
                          fontSize: "1rem",
                        }}
                      >
                        {priceDiffObj.priceDiff} (
                        {priceDiffObj.diffPer.toFixed(2)}%) {selectedRange}
                      </Typography>
                    </Typography>
                  </div>
                  <Divider />
                  <div style={{ padding: "0 20px" }}>
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">
                          Minimum no. of Units
                        </Typography>
                        <Typography variant="h6">
                          {product?.min_units ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">
                          Price per Unit
                        </Typography>
                        <Typography variant="h6">
                          {FormatNumber(productPrice) ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ paddingTop: "10px" }}
                        color="textSecondary"
                      >
                        Settlement Date
                      </Typography>
                      <Typography variant="h6">
                        {moment(product?.price.settlement_date).format(
                          "DD/MM/YYYY"
                        ) ?? "-"}
                      </Typography>
                    </Grid>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 1,
                        paddingTop: "10px",
                      }}
                    >
                      <TextField
                        value={units}
                        variant="outlined"
                        size="small"
                        type="number"
                        inputProps={{
                          // readOnly: true,
                          style: { textAlign: "center", width: "100%" },
                        }}
                        onChange={(e) => handleUnitChange(e)}
                        sx={{ mx: 1 }}
                      />
                    </Box>

                    <Box display={"flex"} justifyContent={"center"}>
                      <Link
                        variant="body2"
                        sx={{
                          mt: 1,
                          color: "red",
                          textAlign: "center",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        onClick={(e: any) => {
                          setIsPriceSlabOpen(true);
                        }}
                      >
                        Price Range
                      </Link>
                    </Box>

                    <Grid container spacing={1} sx={{ mt: 2 }}>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          Total Investment Amount
                        </Typography>
                        <Typography variant="h6">
                          ₹ {(units * productPrice).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        mt: 3,
                        backgroundColor: "#1976d2",
                        ":hover": { backgroundColor: "#0052cc" },
                      }}
                    >
                      {isFormSubmit ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Invest Now"
                      )}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {isPriceSlabOpen && (
        <ViewPriceList
          isPriceSlabOpen={isPriceSlabOpen}
          setIsPriceSlabOpen={setIsPriceSlabOpen}
          price={product.price.price}
          token={accessToken}
          date={product.price.trade_date}
          isin={isin}
          // units={product.price.units}
        />
      )}

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
};

export default PreIpoDetailView;
