import { Alert, CircularProgress, Grid, Snackbar } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCellEditStopParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { saveAs } from "file-saver";
import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import React, { useEffect, useState } from "react";
import Form from "./Form";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Dashboard/Loading";
import { Download } from "@mui/icons-material";

export default function Investment(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const closeHandler = (event) => {
    event.preventDefault();
    setError(false);
    setErrorMessage("");
  };

  const navigate = useNavigate();

  const [investmentList, setInvestmentList] = useState([]);
  const [invtType, setInvtType] = useState({
    1: "Individual",
    13: "Proprietorship",
    6: "Partnership",
    4: "Company",
    47: "LLP",
  });
  const [statusOfinvestMentList, setstatusOfinvestMentList] = useState(1);
  const [isDownloading, setDownloading] = useState(false);

  const BankName = [
    { code: "AUB", title: "AU Small Finance Bank" },
    { code: "AXC", title: "Axis Bank Corporate" },
    { code: "UTI", title: "Axis Bank Ltd" },
    { code: "BNPP", title: "BNP Paribas Bank" },
    { code: "BDN", title: "Bandhan Bank" },
    { code: "BBC", title: "Bank of Baroda - Corporate Banking" },
    { code: "BBR", title: "Bank of Baroda - Retail Banking" },
    { code: "BOI", title: "Bank of India" },
    { code: "BOM", title: "Bank of Maharashtra" },
    { code: "CIT", title: "CITI BANK" },
    { code: "CNB", title: "Canara Bank" },
    { code: "CSBF", title: "Capital Small Bank Finance Ltd" },
    { code: "CBI", title: "Central Bank of India" },
    { code: "CUB", title: "City Union Bank" },
    { code: "DBK", title: "Deutsche Bank" },
    { code: "EQB", title: "Equitas Small Finance Bank" },
    { code: "FBK", title: "Federal Bank" },
    { code: "HDF", title: "HDFC Bank Ltd" },
    { code: "HSB", title: "HSBC" },
    { code: "ICI", title: "ICICI Bank Ltd" },
    { code: "ICO", title: "ICICI Bank Ltd - Corporate" },
    { code: "IDB", title: "IDBI Bank" },
    { code: "IDC", title: "IDBI Corporate" },
    { code: "IDN", title: "IDFC First Bank" },
    { code: "INB", title: "INDIAN BANK" },
    { code: "IDS", title: "IndusInd Bank" },
    { code: "KBL", title: "Karnataka Bank Ltd" },
    { code: "KVB", title: "Karur Vysya Bank" },
    { code: "162", title: "Kotak Bank" },
    { code: "CPN", title: "Punjab National Bank - Corporate Banking" },
    { code: "PNB", title: "Punjab National Bank - Retail Banking" },
    { code: "RBL", title: "Ratnakar Bank" },
    { code: "RTC", title: "Ratnakar Corporate Banking" },
    { code: "SIB", title: "South Indian Bank" },
    { code: "SCB", title: "Standard Chartered Bank" },
    { code: "SBI", title: "State Bank of India" },
    { code: "SBOM", title: "State Bank of Mauritius Ltd" },
    { code: "UBI", title: "Union Bank of India" },
    { code: "YBK", title: "Yes Bank Ltd" },
  ];

  const actionHandler = (type, id) => {
    setLoading(true);

    if (type == "delete") {
      axios
        .delete(`${process.env.REACT_APP_BACKEND_HOST}v1/super/investment`, {
          headers: { Authorization: `Bearer ${props.accessToken}` },
          data: {
            id: id,
          },
        })
        .then(({ data }) => {
          // setInvestmentList(data.investments);
          getReceivablesData({ page: 1, limit: 20 });
          setLoading(false);
        });
    }

    if (type == "edit") {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_HOST}v1/super/investment`,
          {
            id: id,
          },
          {
            headers: { Authorization: `Bearer ${props.accessToken}` },
          }
        )
        .then(({ data }) => {
          // setInvestmentList(data.investments);
          setLoading(false);
        });
    }
  };

  const [columns, setColumns] = useState([
    {
      field: "idx",
      headerName: "SN",
      width: 80,
      renderHeader: () => <strong>SN</strong>,
    },
    {
      field: "org",
      headerName: "Name",
      width: 180,
      renderHeader: () => <strong>Name</strong>,
    },
    {
      field: "type",
      headerName: "Investment For",
      width: 180,
      renderHeader: () => <strong>Investment For</strong>,
    },
    //{ field: 'frequency', headerName: 'Frequency', width: 180 },
    // { field: 'amount', headerName: 'Amount of Investment', width: 180 },
    {
      field: "fund",
      headerName: "Fund",
      width: 220,
      renderHeader: () => <strong>Fund</strong>,
    },
    {
      field: "bank",
      headerName: "Bank",
      width: 280,
      renderHeader: () => <strong>Bank</strong>,
    },

    // { field: 'portfolio', headerName: 'Current Portfolio amount', width: 180 },
    // { field: 'returns', headerName: 'Return generated', type: 'number' },
    {
      field: "Actions",
      headerName: "action",
      width: 450,
      renderHeader: () => <strong>Actions</strong>,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid
              item
              className="bills-pay"
              py={1}
              px={2}
              style={{
                background: "orange",
                marginRight: "1rem",
                width: "6rem",
                textAlign: "center",
              }}
              onClick={() => {
                localStorage.setItem("user", JSON.stringify(params.row));
                params.row.unique_id
                  ? navigate(
                      `/dashboardSuper/view/gold/${params.row.user_id.id}`
                    )
                  : navigate(`/dashboardSuper/view/${params.row.folio.Folio}`, {
                      state: params.row,
                    });
              }}
            >
              View
            </Grid>
            <Grid
              item
              className="bills-pay"
              py={1}
              px={2}
              style={{ marginRight: "1rem", textAlign: "center" }}
              onClick={() => {
                localStorage.setItem("user", JSON.stringify(params.row));
                params.row.folio
                  ? navigate(
                      `/dashboardSuper/transaction/${params.row.folio.Folio}`,
                      {
                        state: params.row,
                      }
                    )
                  : navigate(
                      `/dashboardSuper/gold/transaction/${params.row.user_id.id}`
                    );
              }}
            >
              Transaction History
            </Grid>
            <Grid
              item
              className="bills-pay"
              py={1}
              px={2}
              style={{
                marginRight: "1rem",
                textAlign: "center",
                background: "green",
              }}
              onClick={() => {
                navigate(
                  `/dashboardSuper/investment/folios/${params.row.folio.Folio}`,
                  { state: params.row }
                );
              }}
            >
              Folios
            </Grid>
            {/* <Grid
              item
              className="bills-pay"
              py={1}
              px={2}
              style={{ marginRight: "1rem", textAlign: "center",background: "green", }}
              onClick={() => {
                navigate(
                  `/dashboardSuper/transaction/reports`, { state: params.row }
                );
              }}
            >
              Reports
            </Grid> */}

            {/* {params.row.status == 1 && (
              <>
                <Grid
                  item
                  className="bills-pay"
                  py={1}
                  px={2}
                  style={{ marginRight: "1rem" }}
                  onClick={() => {
                    navigate(`/dashboardSuper/add-investment`, {
                      state: params.row,
                    });
                  }}
                >
                  Continue
                </Grid>
              </>
            )} */}

            {/* <Grid
                        item
                        className="bills-pay"
                        style={{ background: 'red' }}
                        py={1}
                        px={2}
                        onClick={()=>{
                            if(!window.confirm('Are you sure to delete the investment?')) return;
                            actionHandler('delete',params.id)
                        }}

                    >
                        Delete
                    </Grid> */}
            <span></span>
          </div>
        );
      },
    },
  ]);

  const [loading, setLoading] = useState(false);

  const getReceivablesData = (filter: { page: number; limit: number }) => {
    setLoading(true);
    const { page, limit } = filter;
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/super/investment`, {
        headers: { Authorization: `Bearer ${props.accessToken}` },
        params: {
          limit,
          page,
        },
      })
      .then(({ data }) => {
        const investors = data.investments.filter(
          (ele) =>
            ele.fundType == "Various funds through NSE" ||
            ele.hasOwnProperty("unique_id")
        );
        setInvestmentList(investors);
        setLoading(false);
      });
  };

  useEffect(() => {
    getReceivablesData({ page: 1, limit: 100 });
    localStorage.removeItem("user");
  }, []);

  const excelDownloadHandler = async (event) => {
    event.preventDefault();
    setDownloading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/report-excel`,
        {
          headers: { Authorization: `Bearer ${props.accessToken}` },
          responseType: "blob",
        }
      );
      debugger;
      const excelFile = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      saveAs(excelFile, `report-${formattedDate}.xlsx`);
    } catch (error: any) {
      setError(true);
      setErrorMessage("Cannot download reports");
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <Grid
      item
      xs={12}
      px={3}
      mt={4}
      sx={{ maxWidth: "100vw", height: "100vh" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "600",
            margin: "0px 0px 17px 5px",
          }}
        >
          All Investments
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            style={{ marginTop: "-3px", height: "37px" }}
            variant="contained"
            color="primary"
            endIcon={<Download />}
            onClick={excelDownloadHandler}
          >
            <CircularProgress
              size={15}
              color="inherit"
              style={{
                display: isDownloading ? "block" : "none",
                marginRight: "8px",
              }}
            />{" "}
            Report
          </Button>
          <Button
            style={{ marginTop: "-3px", height: "37px" }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/dashboardSuper/transactions")}
          >
            Transaction Summary
          </Button>
        </Box>
      </div>
      <div style={{ height: "100vh", width: "100%" }}>
        <DataGrid
          //  hideFooter={true}
          rowsPerPageOptions={[50, 100, 1000]}
          rows={investmentList.map((each: any, idx: number) => {
            // console.log(each);
            const obj = {};
            Object.keys(each).forEach((key) => {
              let value = each[key];
              if (key === "amount" || key === "portfolio" || key == "returns") {
                value = value.toLocaleString("en-IN");
              }
              //  if(key=='status') setstatusOfinvestMentList(each[key])
              obj[key] = value;
            });
            const bankNames = each?.bank_list
              ?.map((ele) => {
                return ele.BANK_NAME ?? ele.bankName;
              })
              .join(",");
            return {
              ...obj,
              id: each._id,
              idx: idx + 1,
              org: each?.investor_name
                ? each?.investor_name
                : each.user_id?.name,
              fund: each.fund_id?.name,
              bank: bankNames,
              is_OTBM: each.is_OTBM,
              status: each.status,
              type: invtType[each.type],
            };
          })}
          columns={columns.map((each) => {
            return {
              ...each,
              headerAlign: "center",
              align: "center",
            };
          })}
        />
      </div>
      <Snackbar open={isError} autoHideDuration={6000} onClose={closeHandler}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Grid>
  );
}
