import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { FormatNumber, ConvertToPercentage } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import DefaultPage from "../DefaultPage";
import AccountModal from "./AccountModal";
import KycModal from "./KycModal";
import BankModal from "./BankModal";
import PaymentPage from "./CashFreePayment";
import { SocketContext } from "../../../Store/SocketContext";
import TabsButtons from "../TabsButtons";
import InfoIcon from "@mui/icons-material/Info";

export default function GoldInvestment({ user, accessToken }) {
  const [userData, setUserData] = useState<any>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [openAccount, setOpenAccount] = useState<boolean>(false);
  const [isKycOpen, setIsKycOpen] = useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [storeState, dispatch] = useAppContext();
  const [tranx, setTranx] = useState<any>([]);
  const [Data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const navigate = useNavigate();

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);

  const fetchAccountData = async () => {
    setLoading(true);

    try {
      axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/profile`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(({ data }) => {
          if (!data.succ) {
            return;
          }

          let userData = data.data;

          setUserData(userData);
          setIsUpdated(false);
          setTimeout(() => {
            getTransactionReports();
          }, 2000);
        });
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  useEffect(() => {
    if (isUpdated) fetchAccountData();
  }, [isUpdated]);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const getTransactionReports = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/investment/transactionsReport`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(async (res) => {
        const { data } = res;

        if (!data.succ) {
          setIsFail(true);
          setMsg(data.message);
          setData({});
          setEmpty(false);
          return;
        }

        setLoading(false);

        const metalData = data.data;

        // Object.keys(metalData).forEach((metal) => {
        //   if (metalData[metal].giftQuantity > 0) {
        //     metalData[`${metal} (Gift)`] = {
        //       quantity: metalData[metal].giftQuantity,
        //       investedAmount: null,
        //       metal: metal,
        //     };
        //     metalData[metal] = {
        //       ...metalData[metal],
        //       metal: metal,
        //     };
        //   } else {
        //     metalData[metal] = {
        //       ...metalData[metal],
        //       metal: metal,
        //     };
        //   }
        // });

        // console.log(metalData);

        setData(metalData);
      })
      .catch((error) => {
        setData({});
        setEmpty(false);
        setMsg(error);
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [storeState.ACTIVEINVETOR]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TabsButtons investment={"gold"} page={"home"} />

          {(!userData ||
            userData?.user?.kyc_status === 0 ||
            !userData?.bank) && (
            <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
              <Paper elevation={1} sx={{ p: { xs: 4, sm: 4 } }}>
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="h5"
                  sx={{ mb: 2 }}
                >
                  Gold Investment
                </Typography>
                <Grid container spacing={2}>
                  <Grid xs={11} sm={6} md={5}>
                    <Card>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography style={{ fontWeight: 600 }} variant="h6">
                            Create Account
                          </Typography>
                          {userData ? (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => setOpenAccount(true)}
                            >
                              Done
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => setOpenAccount(true)}
                            >
                              Pending
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                    <Card sx={{ marginTop: { xs: 2, md: 5 } }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography style={{ fontWeight: 600 }} variant="h6">
                            KYC
                          </Typography>
                          {userData?.user?.kyc_status === 0 ? (
                            <Button
                              variant="contained"
                              onClick={() => setIsKycOpen(true)}
                            >
                              Pending
                            </Button>
                          ) : userData?.user?.kyc_status === 1 ? (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => setIsKycOpen(true)}
                            >
                              Done
                            </Button>
                          ) : (
                            ""
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={11} sm={6} md={5}>
                    <Card sx={{ alignSelf: "start" }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography style={{ fontWeight: 600 }} variant="h6">
                            Bank Details
                          </Typography>

                          {userData?.bank ? (
                            <Button
                              variant="contained"
                              color="success"
                              // onClick={() => setOpenAccount(true)}
                            >
                              Done
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => setIsOpenBank(true)}
                            >
                              Pending
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
            <Divider sx={{ mb: 2 }} />
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                overflow: { xs: "scroll", sm: "hide" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  minWidth: { xs: "50rem", sm: "69vw" },
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    style={{ fontWeight: 600, color: "grey" }}
                    variant="body1"
                  >
                    Gold / Silver
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    style={{ fontWeight: 600, color: "grey" }}
                    variant="body1"
                  >
                    Units (In Grams)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    style={{ fontWeight: 600, color: "grey" }}
                    variant="body1"
                  >
                    Amount Invested (Excluding Tax.)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    style={{ fontWeight: 600, color: "grey" }}
                    variant="body1"
                  >
                    Actions
                  </Typography>
                </Grid>
              </Box>
              <Divider
                sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
              />
              {!isEmpty &&
                Object.entries(Data)?.map((each, idx) => {
                  const key = each[0];

                  const value: any = each[1];

                  const quantity = value.quantity.toFixed(4);

                  return (
                    <>
                      <Box
                        key={idx}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: { xs: "50rem", sm: "69vw" },
                          overflowX: { xs: "scroll", sm: "hidden" },
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h6">
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2.5}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="h6"
                            display="flex"
                            alignItems="center"
                            gap={0.5}
                          >
                            {quantity}
                            {/* <Tooltip
                              title={
                                <Box
                                  sx={{ fontSize: "1rem", textAlign: "center" }}
                                >
                                  <div>
                                    Purchase :{" "}
                                    {value.purchaseQuantity.toFixed(4)}
                                  </div>
                                  <div>
                                    Gift : {value.giftQuantity.toFixed(4)}
                                  </div>
                                </Box>
                              }
                              arrow
                              placement="top" // Ensures tooltip appears above the icon
                            >
                              <InfoIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                              />
                            </Tooltip> */}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2.5}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="h6">
                              {value.investedAmount
                                ? FormatNumber(value.investedAmount)
                                : "N/A"}
                            </Typography>
                            {/* <Typography
                          variant="caption"
                          color={value.gain < 0 ? "red" : "#32CD32"}
                          sx={{ textAlign: "end" }}
                        >
                          {value.gain < 0
                            ? FormatNumber(value?.gain)
                            : `+ ${FormatNumber(value?.gain)}`}
                        </Typography> */}
                          </div>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          sm={6}
                          md={4}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px",
                                width: "100px",
                                borderRadius: "5px",
                                textAlign: "center",
                                color: "white",
                                backgroundColor: "#ffa500",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                navigate(`/dashboardAdmin/goldInvesting`, {
                                  state: {
                                    key: value.metal,
                                    value: value,
                                    user: userData.user,
                                  },
                                })
                              }
                            >
                              Buy More
                            </div>
                            <div
                              style={{
                                padding: "10px",
                                width: "100px",
                                borderRadius: "5px",
                                textAlign: "center",
                                color: "white",
                                backgroundColor: "#318cd6",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                navigate(`/dashboardAdmin/goldRedeem`, {
                                  state: {
                                    key: value.metal,
                                    value: value,
                                  },
                                })
                              }
                            >
                              Redeem
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      {idx !== tranx.length - 1 && (
                        <Divider
                          sx={{
                            mt: 2,
                            mb: 2,
                            minWidth: { xs: "50rem", sm: "69vw" },
                          }}
                        />
                      )}
                    </>
                  );
                })}
            </Box>

            {/* *** Mobile view *** */}
            <Box
              sx={{
                display: {
                  xs: "flex",
                  flexDirection: "column",
                  gap: 3,
                  sm: "none",
                  p: 0,
                },
              }}
            >
              {!isEmpty &&
                Object.entries(Data)?.map((each, idx) => {
                  const key = each[0];
                  const value: any = each[1];
                  const quantity = value.quantity.toFixed(4);
                  return (
                    <>
                      <Paper elevation={1} sx={{ padding: 1 }}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                          gap={1}
                        >
                          <Typography
                            style={{ fontWeight: 600, color: "grey" }}
                            variant="body1"
                          >
                            Gold / Silver
                          </Typography>
                          <Typography
                            style={{ fontWeight: 600 }}
                            variant="body1"
                          >
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </Typography>
                        </Grid>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          display="flex"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Typography
                              style={{ fontWeight: 600, color: "grey" }}
                              variant="body1"
                            >
                              Units
                            </Typography>
                          </Grid>
                          <Grid item xs={6} display="flex" alignItems="center">
                            <Typography
                              variant="h6"
                              display="flex"
                              alignItems="center"
                              gap={0.5}
                            >
                              {quantity}
                              <Tooltip
                                title={
                                  <Box
                                    sx={{
                                      fontSize: "1rem",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div>
                                      Purchase : {value.purchaseQuantity}
                                    </div>
                                    <div>Gift : {value.giftQuantity}</div>
                                  </Box>
                                }
                                arrow
                                placement="top" // Ensures tooltip appears above the icon
                              >
                                <InfoIcon
                                  sx={{ fontSize: "1rem", cursor: "pointer" }}
                                />
                              </Tooltip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          alignItems="center"
                          gap={2}
                        >
                          <Grid item xs={6}>
                            <Typography
                              style={{ fontWeight: 600, color: "grey" }}
                              variant="body1"
                            >
                              Amount Invested (Excluding Tax.)
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="body1"
                            >
                              {FormatNumber(value.investedAmount)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Grid item xs={12} display="flex" alignItems="center">
                          <Grid item xs={6}>
                            <Typography
                              style={{ fontWeight: 600, color: "grey" }}
                              variant="body1"
                            >
                              Actions
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px",
                                width: "100px",
                                borderRadius: "5px",
                                textAlign: "center",
                                color: "white",
                                backgroundColor: "#ffa500",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                navigate(`/dashboardAdmin/goldInvesting`, {
                                  state: { key: key, value: value },
                                })
                              }
                            >
                              Buy More
                            </div>
                            <div
                              style={{
                                padding: "10px",
                                width: "100px",
                                borderRadius: "5px",
                                textAlign: "center",
                                color: "white",
                                backgroundColor: "#318cd6",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                navigate(`/dashboardAdmin/goldRedeem`, {
                                  state: {
                                    key: key,
                                    value: value,
                                  },
                                })
                              }
                            >
                              Redeem
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </>
                  );
                })}
            </Box>
          </Paper>
          {openAccount && (
            <AccountModal
              openAccount={openAccount}
              setOpenAccount={setOpenAccount}
              accessToken={accessToken}
              userData={userData?.user ? userData?.user : user}
              userAddress={userData?.address}
              setIsUpdated={setIsUpdated}
              setSuccess={setSuccess}
              setError={setError}
              setMsg={setMsg}
            />
          )}
          {isKycOpen && (
            <KycModal
              isKycOpen={isKycOpen}
              setIsKycOpen={setIsKycOpen}
              userData={userData?.user}
              panData={
                userData?.user?.pan
                  ? userData?.user?.pan?.panNumber
                  : user.panNumber
              }
              accessToken={accessToken}
              setIsUpdated={setIsUpdated}
              setSuccess={setSuccess}
              setError={setError}
              setMsg={setMsg}
            />
          )}
          {isOpenBank && (
            <BankModal
              isOpenBank={isOpenBank}
              setIsOpenBank={setIsOpenBank}
              userData={userData?.user}
              bank={userBank}
              accessToken={accessToken}
              setSuccess={setSuccess}
              setIsUpdated={setIsUpdated}
              setError={setError}
              setMsg={setMsg}
            />
          )}
          {/* <PaymentPage accessToken={accessToken} /> */}

          <Snackbar
            open={isSuccess}
            autoHideDuration={3000}
            onClose={closeHandleSnackbar}
          >
            <Alert severity="success">{msg}</Alert>
          </Snackbar>
          <Snackbar
            open={isError}
            autoHideDuration={3000}
            onClose={closeHandleSnackbar}
          >
            <Alert severity="error">{msg}</Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
