import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

const UploadFile = ({
  isUploadFile,
  setIsUploadFile,
  transactionId,
  setIsUpdate,
  accessToken,
  setSuccess,
  setError,
  setMsg,
}: any) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("attachments", file);
    formData.append("id", transactionId);
    formData.append("user_id", id as string);

    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/otherInvestments/upload/attachment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          return;
        }

        setIsUpdate(true);

        setMsg(data.message);
        setSuccess(true);
        setIsUploadFile(false);
      })
      .catch((error) => {
        setMsg(error);
        return;
      });

    setIsLoading(false);
  };

  return (
    <Fragment>
      <Dialog
        // fullScreen={"md"}
        open={isUploadFile}
        onClose={() => setIsUploadFile(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Upload File</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <TextField
                label="Pan Card"
                onChange={(e) => handleFileChange(e)}
                type="file"
                name="Upload file"
                variant="outlined"
                margin="normal"
                fullWidth
                focused
                required
              />
              {/* {account && (
                    <CloudDownloadOutlinedIcon
                      color="primary"
                      style={{ cursor: "pointer", fontSize: "26px" }}
                      onClick={() => {
                        handleDownloadFile(account?.pan_attachment, "pan");
                      }}
                    />
                  )} */}
            </div>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsUploadFile(false)}
              >
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default UploadFile;
