import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useAppContext } from "../../../Store/AppContext";
import { useParams } from "react-router-dom";

const SuperAdminGoldMemberBankModal = ({
  isOpenBank,
  setIsOpenBank,
  userData,
  bank,
  accessToken,
  setIsUpdated,
  setError,
  setSuccess,
  setMsg,
}: any) => {
  const [storeState, dispatch] = useAppContext();
  const { id } = useParams();

  const [account, setAccount] = useState<string>(bank ? bank[0].ACNUM : "");
  const [accountName, setAccountName] = useState<string>(
    userData?.name ? userData?.name : ""
  );

  const [bankName, setBankName] = useState<string>(
    bank ? bank[0].BANK_NAME : ""
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [ifsc, setIfsc] = useState<string>(bank ? bank[0].IFSC : "");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setIsOpenBank(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    let bankObj = {
      user_id: id,
      bankName: bankName,
      accountNumber: account,
      accountName: accountName,
      ifscCode: ifsc,
    };

    setIsLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/gold/member/addBankAccount`,
        bankObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(({ data }) => {
        if (!data.succ) {
          console.log("Error from Add bank details api ->", data.message);
          setError(true);
          setMsg(data.message);
        }

        setSuccess(true);
        setMsg(data.message);
        setIsUpdated(true);
        handleClose();
      })
      .catch((error) => {
        console.log("Error from bank Details  ->", error);
      });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={isOpenBank}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add Bank Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Bank Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Account Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Account Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Ifac Code"
              variant="outlined"
              margin="normal"
              fullWidth
              value={ifsc}
              onChange={(e) => setIfsc(e.target.value)}
            />
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default SuperAdminGoldMemberBankModal;
