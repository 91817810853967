import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { FormatNumber } from "../../../utils/formatNumber";
import TabsButtons from "../../Admin/TabsButtons";

export default function GoldMemberInvestment({ accessToken }) {
  const { id } = useParams();

  const [Data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const getTransactionReports = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/gold-member/transactions?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(async (res) => {
        const { data } = res;

        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          setData({});
          setEmpty(false);
          return;
        }

        setData(data.data);
      })
      .catch((error) => {
        setData({});
        setEmpty(false);
        setMsg(error);
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    getTransactionReports();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <TabsButtons investment={"gold"} page={"view"} />

      <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            overflow: { xs: "scroll", sm: "hide" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              minWidth: { xs: "50rem", sm: "69vw" },
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Gold / Silver
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Units
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Amount Invested (Excluding Tax.)
              </Typography>
            </Grid>
          </Box>
          <Divider
            sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
          />
          {!isEmpty &&
            Object.entries(Data)?.map((each, idx) => {
              const key = each[0];

              const value: any = each[1];

              const quantity = value.quantity.toFixed(4);

              return (
                <>
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: { xs: "50rem", sm: "69vw" },
                      overflowX: { xs: "scroll", sm: "hidden" },
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                      >
                        {quantity}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">
                          {value.investedAmount
                            ? FormatNumber(value.investedAmount)
                            : "N/A"}
                        </Typography>
                        {/* <Typography
                          variant="caption"
                          color={value.gain < 0 ? "red" : "#32CD32"}
                          sx={{ textAlign: "end" }}
                        >
                          {value.gain < 0
                            ? FormatNumber(value?.gain)
                            : `+ ${FormatNumber(value?.gain)}`}
                        </Typography> */}
                      </div>
                    </Grid>
                  </Box>

                  <Divider
                    sx={{
                      mt: 2,
                      mb: 2,
                      minWidth: { xs: "50rem", sm: "69vw" },
                    }}
                  />
                </>
              );
            })}
        </Box>
      </Paper>

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
}
